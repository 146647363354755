/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * Planner API
 * OpenAPI spec version: v1
 */
import {
  useQuery,
  useMutation
} from '@tanstack/react-query'
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from '@tanstack/react-query'
import type {
  ProcessDTO,
  ErrorResponse,
  ProcessDTOCreate,
  PostApiProcessParams,
  GetApiProcessParams,
  CalendarProcessDTOCreate,
  ProcessDTOInvitationsResult,
  ProcessDTOInvitations,
  PostApiProcessIdRestoreParams,
  CalendarConfirmDTOResponse,
  CalendarConfirmDTORequest,
  CalendarDeclineDTOResponse,
  CalendarDeclineDTORequest,
  PostApiProcessDeclineInvitationsParams,
  ProcessDTOUpdate,
  PutApiProcessIdParams,
  ProcessDTODetailed,
  GetApiProcessProcessWithCastParams,
  ProcessDTOLight,
  GetApiProcessFastParams,
  NonAcceptedProcessDTO,
  ScheduleDTO,
  ScheduleDTOQuery,
  CalendarProcessDTO,
  CalendarFastResponseDTO,
  GetApiProcessCalendarFastParams,
  CalendarServiceICalResponseDto,
  CalendarICalParams,
  PostApiProcessTourParams,
  PutApiProcessTourIdParams,
  DeleteApiProcessRecurringParams
} from './dotu.schemas'
import { dotuAxiosInstance } from '../../axios';



// eslint-disable-next-line
  type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

/**
 * @summary Process - Write
 */
export const postApiProcess = (
    processDTOCreate: ProcessDTOCreate,
    params?: PostApiProcessParams,
 options?: SecondParameter<typeof dotuAxiosInstance>,) => {
      return dotuAxiosInstance<ProcessDTO>(
      {url: `/api/Process`, method: 'post',
      headers: {'Content-Type': 'application/json-patch+json', },
      data: processDTOCreate,
        params
    },
      options);
    }
  


export const getPostApiProcessMutationOptions = <TError = ErrorResponse,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProcess>>, TError,{data: ProcessDTOCreate;params?: PostApiProcessParams}, TContext>, request?: SecondParameter<typeof dotuAxiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof postApiProcess>>, TError,{data: ProcessDTOCreate;params?: PostApiProcessParams}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiProcess>>, {data: ProcessDTOCreate;params?: PostApiProcessParams}> = (props) => {
          const {data,params} = props ?? {};

          return  postApiProcess(data,params,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type PostApiProcessMutationResult = NonNullable<Awaited<ReturnType<typeof postApiProcess>>>
    export type PostApiProcessMutationBody = ProcessDTOCreate
    export type PostApiProcessMutationError = ErrorResponse

    /**
 * @summary Process - Write
 */
export const usePostApiProcess = <TError = ErrorResponse,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProcess>>, TError,{data: ProcessDTOCreate;params?: PostApiProcessParams}, TContext>, request?: SecondParameter<typeof dotuAxiosInstance>}
) => {
    
      const mutationOptions = getPostApiProcessMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    /**
 * @summary Process - Read
 */
export const getApiProcess = (
    params?: GetApiProcessParams,
 options?: SecondParameter<typeof dotuAxiosInstance>,signal?: AbortSignal
) => {
      return dotuAxiosInstance<ProcessDTO[]>(
      {url: `/api/Process`, method: 'get',
        params, signal
    },
      options);
    }
  

export const getGetApiProcessQueryKey = (params?: GetApiProcessParams,) => [`/api/Process`, ...(params ? [params]: [])] as const;
  

    
export const getGetApiProcessQueryOptions = <TData = Awaited<ReturnType<typeof getApiProcess>>, TError = unknown>(params?: GetApiProcessParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiProcess>>, TError, TData>, request?: SecondParameter<typeof dotuAxiosInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof getApiProcess>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiProcessQueryKey(params);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiProcess>>> = ({ signal }) => getApiProcess(params, requestOptions, signal);
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type GetApiProcessQueryResult = NonNullable<Awaited<ReturnType<typeof getApiProcess>>>
export type GetApiProcessQueryError = unknown

/**
 * @summary Process - Read
 */
export const useGetApiProcess = <TData = Awaited<ReturnType<typeof getApiProcess>>, TError = unknown>(
 params?: GetApiProcessParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiProcess>>, TError, TData>, request?: SecondParameter<typeof dotuAxiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetApiProcessQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}

export const postApiProcessCalendar = (
    calendarProcessDTOCreate: CalendarProcessDTOCreate,
 options?: SecondParameter<typeof dotuAxiosInstance>,) => {
      return dotuAxiosInstance<ProcessDTO>(
      {url: `/api/Process/Calendar`, method: 'post',
      headers: {'Content-Type': 'application/json-patch+json', },
      data: calendarProcessDTOCreate
    },
      options);
    }
  


export const getPostApiProcessCalendarMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProcessCalendar>>, TError,{data: CalendarProcessDTOCreate}, TContext>, request?: SecondParameter<typeof dotuAxiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof postApiProcessCalendar>>, TError,{data: CalendarProcessDTOCreate}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiProcessCalendar>>, {data: CalendarProcessDTOCreate}> = (props) => {
          const {data} = props ?? {};

          return  postApiProcessCalendar(data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type PostApiProcessCalendarMutationResult = NonNullable<Awaited<ReturnType<typeof postApiProcessCalendar>>>
    export type PostApiProcessCalendarMutationBody = CalendarProcessDTOCreate
    export type PostApiProcessCalendarMutationError = unknown

    export const usePostApiProcessCalendar = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProcessCalendar>>, TError,{data: CalendarProcessDTOCreate}, TContext>, request?: SecondParameter<typeof dotuAxiosInstance>}
) => {
    
      const mutationOptions = getPostApiProcessCalendarMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    /**
 * @summary Invitations - Write
 */
export const postApiProcessSendInvitations = (
    processDTOInvitations: ProcessDTOInvitations,
 options?: SecondParameter<typeof dotuAxiosInstance>,) => {
      return dotuAxiosInstance<ProcessDTOInvitationsResult>(
      {url: `/api/Process/SendInvitations`, method: 'post',
      headers: {'Content-Type': 'application/json-patch+json', },
      data: processDTOInvitations
    },
      options);
    }
  


export const getPostApiProcessSendInvitationsMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProcessSendInvitations>>, TError,{data: ProcessDTOInvitations}, TContext>, request?: SecondParameter<typeof dotuAxiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof postApiProcessSendInvitations>>, TError,{data: ProcessDTOInvitations}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiProcessSendInvitations>>, {data: ProcessDTOInvitations}> = (props) => {
          const {data} = props ?? {};

          return  postApiProcessSendInvitations(data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type PostApiProcessSendInvitationsMutationResult = NonNullable<Awaited<ReturnType<typeof postApiProcessSendInvitations>>>
    export type PostApiProcessSendInvitationsMutationBody = ProcessDTOInvitations
    export type PostApiProcessSendInvitationsMutationError = unknown

    /**
 * @summary Invitations - Write
 */
export const usePostApiProcessSendInvitations = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProcessSendInvitations>>, TError,{data: ProcessDTOInvitations}, TContext>, request?: SecondParameter<typeof dotuAxiosInstance>}
) => {
    
      const mutationOptions = getPostApiProcessSendInvitationsMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    /**
 * @summary Invitations - Write
 */
export const postApiProcessSendUpdateInvitations = (
    processDTOInvitations: ProcessDTOInvitations,
 options?: SecondParameter<typeof dotuAxiosInstance>,) => {
      return dotuAxiosInstance<ProcessDTOInvitationsResult>(
      {url: `/api/Process/SendUpdateInvitations`, method: 'post',
      headers: {'Content-Type': 'application/json-patch+json', },
      data: processDTOInvitations
    },
      options);
    }
  


export const getPostApiProcessSendUpdateInvitationsMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProcessSendUpdateInvitations>>, TError,{data: ProcessDTOInvitations}, TContext>, request?: SecondParameter<typeof dotuAxiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof postApiProcessSendUpdateInvitations>>, TError,{data: ProcessDTOInvitations}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiProcessSendUpdateInvitations>>, {data: ProcessDTOInvitations}> = (props) => {
          const {data} = props ?? {};

          return  postApiProcessSendUpdateInvitations(data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type PostApiProcessSendUpdateInvitationsMutationResult = NonNullable<Awaited<ReturnType<typeof postApiProcessSendUpdateInvitations>>>
    export type PostApiProcessSendUpdateInvitationsMutationBody = ProcessDTOInvitations
    export type PostApiProcessSendUpdateInvitationsMutationError = unknown

    /**
 * @summary Invitations - Write
 */
export const usePostApiProcessSendUpdateInvitations = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProcessSendUpdateInvitations>>, TError,{data: ProcessDTOInvitations}, TContext>, request?: SecondParameter<typeof dotuAxiosInstance>}
) => {
    
      const mutationOptions = getPostApiProcessSendUpdateInvitationsMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    /**
 * @summary Process - Write
 */
export const postApiProcessIdCancel = (
    id: number,
 options?: SecondParameter<typeof dotuAxiosInstance>,) => {
      return dotuAxiosInstance<void>(
      {url: `/api/Process/${id}/Cancel`, method: 'post'
    },
      options);
    }
  


export const getPostApiProcessIdCancelMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProcessIdCancel>>, TError,{id: number}, TContext>, request?: SecondParameter<typeof dotuAxiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof postApiProcessIdCancel>>, TError,{id: number}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiProcessIdCancel>>, {id: number}> = (props) => {
          const {id} = props ?? {};

          return  postApiProcessIdCancel(id,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type PostApiProcessIdCancelMutationResult = NonNullable<Awaited<ReturnType<typeof postApiProcessIdCancel>>>
    
    export type PostApiProcessIdCancelMutationError = unknown

    /**
 * @summary Process - Write
 */
export const usePostApiProcessIdCancel = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProcessIdCancel>>, TError,{id: number}, TContext>, request?: SecondParameter<typeof dotuAxiosInstance>}
) => {
    
      const mutationOptions = getPostApiProcessIdCancelMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    /**
 * @summary Process - Write
 */
export const postApiProcessIdRestore = (
    id: number,
    params?: PostApiProcessIdRestoreParams,
 options?: SecondParameter<typeof dotuAxiosInstance>,) => {
      return dotuAxiosInstance<void>(
      {url: `/api/Process/${id}/Restore`, method: 'post',
        params
    },
      options);
    }
  


export const getPostApiProcessIdRestoreMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProcessIdRestore>>, TError,{id: number;params?: PostApiProcessIdRestoreParams}, TContext>, request?: SecondParameter<typeof dotuAxiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof postApiProcessIdRestore>>, TError,{id: number;params?: PostApiProcessIdRestoreParams}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiProcessIdRestore>>, {id: number;params?: PostApiProcessIdRestoreParams}> = (props) => {
          const {id,params} = props ?? {};

          return  postApiProcessIdRestore(id,params,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type PostApiProcessIdRestoreMutationResult = NonNullable<Awaited<ReturnType<typeof postApiProcessIdRestore>>>
    
    export type PostApiProcessIdRestoreMutationError = unknown

    /**
 * @summary Process - Write
 */
export const usePostApiProcessIdRestore = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProcessIdRestore>>, TError,{id: number;params?: PostApiProcessIdRestoreParams}, TContext>, request?: SecondParameter<typeof dotuAxiosInstance>}
) => {
    
      const mutationOptions = getPostApiProcessIdRestoreMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    /**
 * @summary Invitations - Write
 */
export const postApiProcessCancelInvitations = (
    processDTOInvitations: ProcessDTOInvitations,
 options?: SecondParameter<typeof dotuAxiosInstance>,) => {
      return dotuAxiosInstance<ProcessDTOInvitationsResult>(
      {url: `/api/Process/CancelInvitations`, method: 'post',
      headers: {'Content-Type': 'application/json-patch+json', },
      data: processDTOInvitations
    },
      options);
    }
  


export const getPostApiProcessCancelInvitationsMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProcessCancelInvitations>>, TError,{data: ProcessDTOInvitations}, TContext>, request?: SecondParameter<typeof dotuAxiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof postApiProcessCancelInvitations>>, TError,{data: ProcessDTOInvitations}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiProcessCancelInvitations>>, {data: ProcessDTOInvitations}> = (props) => {
          const {data} = props ?? {};

          return  postApiProcessCancelInvitations(data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type PostApiProcessCancelInvitationsMutationResult = NonNullable<Awaited<ReturnType<typeof postApiProcessCancelInvitations>>>
    export type PostApiProcessCancelInvitationsMutationBody = ProcessDTOInvitations
    export type PostApiProcessCancelInvitationsMutationError = unknown

    /**
 * @summary Invitations - Write
 */
export const usePostApiProcessCancelInvitations = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProcessCancelInvitations>>, TError,{data: ProcessDTOInvitations}, TContext>, request?: SecondParameter<typeof dotuAxiosInstance>}
) => {
    
      const mutationOptions = getPostApiProcessCancelInvitationsMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    export const postApiProcessConfirmInvitations = (
    calendarConfirmDTORequest: CalendarConfirmDTORequest,
 options?: SecondParameter<typeof dotuAxiosInstance>,) => {
      return dotuAxiosInstance<CalendarConfirmDTOResponse>(
      {url: `/api/Process/ConfirmInvitations`, method: 'post',
      headers: {'Content-Type': 'application/json-patch+json', },
      data: calendarConfirmDTORequest
    },
      options);
    }
  


export const getPostApiProcessConfirmInvitationsMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProcessConfirmInvitations>>, TError,{data: CalendarConfirmDTORequest}, TContext>, request?: SecondParameter<typeof dotuAxiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof postApiProcessConfirmInvitations>>, TError,{data: CalendarConfirmDTORequest}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiProcessConfirmInvitations>>, {data: CalendarConfirmDTORequest}> = (props) => {
          const {data} = props ?? {};

          return  postApiProcessConfirmInvitations(data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type PostApiProcessConfirmInvitationsMutationResult = NonNullable<Awaited<ReturnType<typeof postApiProcessConfirmInvitations>>>
    export type PostApiProcessConfirmInvitationsMutationBody = CalendarConfirmDTORequest
    export type PostApiProcessConfirmInvitationsMutationError = unknown

    export const usePostApiProcessConfirmInvitations = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProcessConfirmInvitations>>, TError,{data: CalendarConfirmDTORequest}, TContext>, request?: SecondParameter<typeof dotuAxiosInstance>}
) => {
    
      const mutationOptions = getPostApiProcessConfirmInvitationsMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    export const postApiProcessDeclineInvitations = (
    calendarDeclineDTORequest: CalendarDeclineDTORequest,
    params?: PostApiProcessDeclineInvitationsParams,
 options?: SecondParameter<typeof dotuAxiosInstance>,) => {
      return dotuAxiosInstance<CalendarDeclineDTOResponse>(
      {url: `/api/Process/DeclineInvitations`, method: 'post',
      headers: {'Content-Type': 'application/json-patch+json', },
      data: calendarDeclineDTORequest,
        params
    },
      options);
    }
  


export const getPostApiProcessDeclineInvitationsMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProcessDeclineInvitations>>, TError,{data: CalendarDeclineDTORequest;params?: PostApiProcessDeclineInvitationsParams}, TContext>, request?: SecondParameter<typeof dotuAxiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof postApiProcessDeclineInvitations>>, TError,{data: CalendarDeclineDTORequest;params?: PostApiProcessDeclineInvitationsParams}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiProcessDeclineInvitations>>, {data: CalendarDeclineDTORequest;params?: PostApiProcessDeclineInvitationsParams}> = (props) => {
          const {data,params} = props ?? {};

          return  postApiProcessDeclineInvitations(data,params,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type PostApiProcessDeclineInvitationsMutationResult = NonNullable<Awaited<ReturnType<typeof postApiProcessDeclineInvitations>>>
    export type PostApiProcessDeclineInvitationsMutationBody = CalendarDeclineDTORequest
    export type PostApiProcessDeclineInvitationsMutationError = unknown

    export const usePostApiProcessDeclineInvitations = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProcessDeclineInvitations>>, TError,{data: CalendarDeclineDTORequest;params?: PostApiProcessDeclineInvitationsParams}, TContext>, request?: SecondParameter<typeof dotuAxiosInstance>}
) => {
    
      const mutationOptions = getPostApiProcessDeclineInvitationsMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    /**
 * @summary Process - Write
 */
export const putApiProcessId = (
    id: number,
    processDTOUpdate: ProcessDTOUpdate,
    params?: PutApiProcessIdParams,
 options?: SecondParameter<typeof dotuAxiosInstance>,) => {
      return dotuAxiosInstance<ProcessDTO>(
      {url: `/api/Process/${id}`, method: 'put',
      headers: {'Content-Type': 'application/json-patch+json', },
      data: processDTOUpdate,
        params
    },
      options);
    }
  


export const getPutApiProcessIdMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putApiProcessId>>, TError,{id: number;data: ProcessDTOUpdate;params?: PutApiProcessIdParams}, TContext>, request?: SecondParameter<typeof dotuAxiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof putApiProcessId>>, TError,{id: number;data: ProcessDTOUpdate;params?: PutApiProcessIdParams}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putApiProcessId>>, {id: number;data: ProcessDTOUpdate;params?: PutApiProcessIdParams}> = (props) => {
          const {id,data,params} = props ?? {};

          return  putApiProcessId(id,data,params,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type PutApiProcessIdMutationResult = NonNullable<Awaited<ReturnType<typeof putApiProcessId>>>
    export type PutApiProcessIdMutationBody = ProcessDTOUpdate
    export type PutApiProcessIdMutationError = unknown

    /**
 * @summary Process - Write
 */
export const usePutApiProcessId = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putApiProcessId>>, TError,{id: number;data: ProcessDTOUpdate;params?: PutApiProcessIdParams}, TContext>, request?: SecondParameter<typeof dotuAxiosInstance>}
) => {
    
      const mutationOptions = getPutApiProcessIdMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    /**
 * @summary Process - Read
 */
export const getApiProcessId = (
    id: number,
 options?: SecondParameter<typeof dotuAxiosInstance>,signal?: AbortSignal
) => {
      return dotuAxiosInstance<ProcessDTODetailed>(
      {url: `/api/Process/${id}`, method: 'get', signal
    },
      options);
    }
  

export const getGetApiProcessIdQueryKey = (id: number,) => [`/api/Process/${id}`] as const;
  

    
export const getGetApiProcessIdQueryOptions = <TData = Awaited<ReturnType<typeof getApiProcessId>>, TError = unknown>(id: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiProcessId>>, TError, TData>, request?: SecondParameter<typeof dotuAxiosInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof getApiProcessId>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiProcessIdQueryKey(id);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiProcessId>>> = ({ signal }) => getApiProcessId(id, requestOptions, signal);
    
      
      
   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions}}

export type GetApiProcessIdQueryResult = NonNullable<Awaited<ReturnType<typeof getApiProcessId>>>
export type GetApiProcessIdQueryError = unknown

/**
 * @summary Process - Read
 */
export const useGetApiProcessId = <TData = Awaited<ReturnType<typeof getApiProcessId>>, TError = unknown>(
 id: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiProcessId>>, TError, TData>, request?: SecondParameter<typeof dotuAxiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetApiProcessIdQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}

/**
 * @summary Process - Write
 */
export const deleteApiProcessId = (
    id: number,
 options?: SecondParameter<typeof dotuAxiosInstance>,) => {
      return dotuAxiosInstance<void>(
      {url: `/api/Process/${id}`, method: 'delete'
    },
      options);
    }
  


export const getDeleteApiProcessIdMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteApiProcessId>>, TError,{id: number}, TContext>, request?: SecondParameter<typeof dotuAxiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteApiProcessId>>, TError,{id: number}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteApiProcessId>>, {id: number}> = (props) => {
          const {id} = props ?? {};

          return  deleteApiProcessId(id,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type DeleteApiProcessIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteApiProcessId>>>
    
    export type DeleteApiProcessIdMutationError = unknown

    /**
 * @summary Process - Write
 */
export const useDeleteApiProcessId = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteApiProcessId>>, TError,{id: number}, TContext>, request?: SecondParameter<typeof dotuAxiosInstance>}
) => {
    
      const mutationOptions = getDeleteApiProcessIdMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    /**
 * @summary Process - Read
 */
export const postApiProcessCheckConflictsId = (
    id: number,
 options?: SecondParameter<typeof dotuAxiosInstance>,) => {
      return dotuAxiosInstance<void>(
      {url: `/api/Process/CheckConflicts/${id}`, method: 'post'
    },
      options);
    }
  


export const getPostApiProcessCheckConflictsIdMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProcessCheckConflictsId>>, TError,{id: number}, TContext>, request?: SecondParameter<typeof dotuAxiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof postApiProcessCheckConflictsId>>, TError,{id: number}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiProcessCheckConflictsId>>, {id: number}> = (props) => {
          const {id} = props ?? {};

          return  postApiProcessCheckConflictsId(id,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type PostApiProcessCheckConflictsIdMutationResult = NonNullable<Awaited<ReturnType<typeof postApiProcessCheckConflictsId>>>
    
    export type PostApiProcessCheckConflictsIdMutationError = unknown

    /**
 * @summary Process - Read
 */
export const usePostApiProcessCheckConflictsId = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProcessCheckConflictsId>>, TError,{id: number}, TContext>, request?: SecondParameter<typeof dotuAxiosInstance>}
) => {
    
      const mutationOptions = getPostApiProcessCheckConflictsIdMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    /**
 * @summary Process - Read
 */
export const postApiProcessConfclitNotificationId = (
    id: number,
 options?: SecondParameter<typeof dotuAxiosInstance>,) => {
      return dotuAxiosInstance<void>(
      {url: `/api/Process/ConfclitNotification/${id}`, method: 'post'
    },
      options);
    }
  


export const getPostApiProcessConfclitNotificationIdMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProcessConfclitNotificationId>>, TError,{id: number}, TContext>, request?: SecondParameter<typeof dotuAxiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof postApiProcessConfclitNotificationId>>, TError,{id: number}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiProcessConfclitNotificationId>>, {id: number}> = (props) => {
          const {id} = props ?? {};

          return  postApiProcessConfclitNotificationId(id,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type PostApiProcessConfclitNotificationIdMutationResult = NonNullable<Awaited<ReturnType<typeof postApiProcessConfclitNotificationId>>>
    
    export type PostApiProcessConfclitNotificationIdMutationError = unknown

    /**
 * @summary Process - Read
 */
export const usePostApiProcessConfclitNotificationId = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProcessConfclitNotificationId>>, TError,{id: number}, TContext>, request?: SecondParameter<typeof dotuAxiosInstance>}
) => {
    
      const mutationOptions = getPostApiProcessConfclitNotificationIdMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    /**
 * @summary Process - Read
 */
export const getApiProcessProcessWithCast = (
    params?: GetApiProcessProcessWithCastParams,
 options?: SecondParameter<typeof dotuAxiosInstance>,signal?: AbortSignal
) => {
      return dotuAxiosInstance<ProcessDTO[]>(
      {url: `/api/Process/ProcessWithCast`, method: 'get',
        params, signal
    },
      options);
    }
  

export const getGetApiProcessProcessWithCastQueryKey = (params?: GetApiProcessProcessWithCastParams,) => [`/api/Process/ProcessWithCast`, ...(params ? [params]: [])] as const;
  

    
export const getGetApiProcessProcessWithCastQueryOptions = <TData = Awaited<ReturnType<typeof getApiProcessProcessWithCast>>, TError = unknown>(params?: GetApiProcessProcessWithCastParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiProcessProcessWithCast>>, TError, TData>, request?: SecondParameter<typeof dotuAxiosInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof getApiProcessProcessWithCast>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiProcessProcessWithCastQueryKey(params);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiProcessProcessWithCast>>> = ({ signal }) => getApiProcessProcessWithCast(params, requestOptions, signal);
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type GetApiProcessProcessWithCastQueryResult = NonNullable<Awaited<ReturnType<typeof getApiProcessProcessWithCast>>>
export type GetApiProcessProcessWithCastQueryError = unknown

/**
 * @summary Process - Read
 */
export const useGetApiProcessProcessWithCast = <TData = Awaited<ReturnType<typeof getApiProcessProcessWithCast>>, TError = unknown>(
 params?: GetApiProcessProcessWithCastParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiProcessProcessWithCast>>, TError, TData>, request?: SecondParameter<typeof dotuAxiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetApiProcessProcessWithCastQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}

/**
 * @summary Process - Read
 */
export const getApiProcessFast = (
    params?: GetApiProcessFastParams,
 options?: SecondParameter<typeof dotuAxiosInstance>,signal?: AbortSignal
) => {
      return dotuAxiosInstance<ProcessDTOLight[]>(
      {url: `/api/Process/Fast`, method: 'get',
        params, signal
    },
      options);
    }
  

export const getGetApiProcessFastQueryKey = (params?: GetApiProcessFastParams,) => [`/api/Process/Fast`, ...(params ? [params]: [])] as const;
  

    
export const getGetApiProcessFastQueryOptions = <TData = Awaited<ReturnType<typeof getApiProcessFast>>, TError = unknown>(params?: GetApiProcessFastParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiProcessFast>>, TError, TData>, request?: SecondParameter<typeof dotuAxiosInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof getApiProcessFast>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiProcessFastQueryKey(params);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiProcessFast>>> = ({ signal }) => getApiProcessFast(params, requestOptions, signal);
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type GetApiProcessFastQueryResult = NonNullable<Awaited<ReturnType<typeof getApiProcessFast>>>
export type GetApiProcessFastQueryError = unknown

/**
 * @summary Process - Read
 */
export const useGetApiProcessFast = <TData = Awaited<ReturnType<typeof getApiProcessFast>>, TError = unknown>(
 params?: GetApiProcessFastParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiProcessFast>>, TError, TData>, request?: SecondParameter<typeof dotuAxiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetApiProcessFastQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}

/**
 * @summary Process - Read
 */
export const postApiProcessGetCanceledProcesses = (
    
 options?: SecondParameter<typeof dotuAxiosInstance>,) => {
      return dotuAxiosInstance<ProcessDTO[]>(
      {url: `/api/Process/GetCanceledProcesses`, method: 'post'
    },
      options);
    }
  


export const getPostApiProcessGetCanceledProcessesMutationOptions = <TError = unknown,
    TVariables = void,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProcessGetCanceledProcesses>>, TError,TVariables, TContext>, request?: SecondParameter<typeof dotuAxiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof postApiProcessGetCanceledProcesses>>, TError,TVariables, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiProcessGetCanceledProcesses>>, TVariables> = () => {
          

          return  postApiProcessGetCanceledProcesses(requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type PostApiProcessGetCanceledProcessesMutationResult = NonNullable<Awaited<ReturnType<typeof postApiProcessGetCanceledProcesses>>>
    
    export type PostApiProcessGetCanceledProcessesMutationError = unknown

    /**
 * @summary Process - Read
 */
export const usePostApiProcessGetCanceledProcesses = <TError = unknown,
    TVariables = void,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProcessGetCanceledProcesses>>, TError,TVariables, TContext>, request?: SecondParameter<typeof dotuAxiosInstance>}
) => {
    
      const mutationOptions = getPostApiProcessGetCanceledProcessesMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    /**
 * @summary Process - Read
 */
export const postApiProcessGetNonAcceptedProcesses = (
    nonAcceptedProcessDTO: NonAcceptedProcessDTO,
 options?: SecondParameter<typeof dotuAxiosInstance>,) => {
      return dotuAxiosInstance<ProcessDTO[]>(
      {url: `/api/Process/GetNonAcceptedProcesses`, method: 'post',
      headers: {'Content-Type': 'application/json-patch+json', },
      data: nonAcceptedProcessDTO
    },
      options);
    }
  


export const getPostApiProcessGetNonAcceptedProcessesMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProcessGetNonAcceptedProcesses>>, TError,{data: NonAcceptedProcessDTO}, TContext>, request?: SecondParameter<typeof dotuAxiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof postApiProcessGetNonAcceptedProcesses>>, TError,{data: NonAcceptedProcessDTO}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiProcessGetNonAcceptedProcesses>>, {data: NonAcceptedProcessDTO}> = (props) => {
          const {data} = props ?? {};

          return  postApiProcessGetNonAcceptedProcesses(data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type PostApiProcessGetNonAcceptedProcessesMutationResult = NonNullable<Awaited<ReturnType<typeof postApiProcessGetNonAcceptedProcesses>>>
    export type PostApiProcessGetNonAcceptedProcessesMutationBody = NonAcceptedProcessDTO
    export type PostApiProcessGetNonAcceptedProcessesMutationError = unknown

    /**
 * @summary Process - Read
 */
export const usePostApiProcessGetNonAcceptedProcesses = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProcessGetNonAcceptedProcesses>>, TError,{data: NonAcceptedProcessDTO}, TContext>, request?: SecondParameter<typeof dotuAxiosInstance>}
) => {
    
      const mutationOptions = getPostApiProcessGetNonAcceptedProcessesMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    /**
 * @summary Process - Read
 */
export const postApiProcessSchedule = (
    scheduleDTOQuery: ScheduleDTOQuery,
 options?: SecondParameter<typeof dotuAxiosInstance>,) => {
      return dotuAxiosInstance<ScheduleDTO[]>(
      {url: `/api/Process/Schedule`, method: 'post',
      headers: {'Content-Type': 'application/json-patch+json', },
      data: scheduleDTOQuery
    },
      options);
    }
  


export const getPostApiProcessScheduleMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProcessSchedule>>, TError,{data: ScheduleDTOQuery}, TContext>, request?: SecondParameter<typeof dotuAxiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof postApiProcessSchedule>>, TError,{data: ScheduleDTOQuery}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiProcessSchedule>>, {data: ScheduleDTOQuery}> = (props) => {
          const {data} = props ?? {};

          return  postApiProcessSchedule(data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type PostApiProcessScheduleMutationResult = NonNullable<Awaited<ReturnType<typeof postApiProcessSchedule>>>
    export type PostApiProcessScheduleMutationBody = ScheduleDTOQuery
    export type PostApiProcessScheduleMutationError = unknown

    /**
 * @summary Process - Read
 */
export const usePostApiProcessSchedule = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProcessSchedule>>, TError,{data: ScheduleDTOQuery}, TContext>, request?: SecondParameter<typeof dotuAxiosInstance>}
) => {
    
      const mutationOptions = getPostApiProcessScheduleMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    export const deleteApiProcessCalendarId = (
    id: number,
 options?: SecondParameter<typeof dotuAxiosInstance>,) => {
      return dotuAxiosInstance<CalendarProcessDTO>(
      {url: `/api/Process/Calendar/${id}`, method: 'delete'
    },
      options);
    }
  


export const getDeleteApiProcessCalendarIdMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteApiProcessCalendarId>>, TError,{id: number}, TContext>, request?: SecondParameter<typeof dotuAxiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteApiProcessCalendarId>>, TError,{id: number}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteApiProcessCalendarId>>, {id: number}> = (props) => {
          const {id} = props ?? {};

          return  deleteApiProcessCalendarId(id,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type DeleteApiProcessCalendarIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteApiProcessCalendarId>>>
    
    export type DeleteApiProcessCalendarIdMutationError = unknown

    export const useDeleteApiProcessCalendarId = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteApiProcessCalendarId>>, TError,{id: number}, TContext>, request?: SecondParameter<typeof dotuAxiosInstance>}
) => {
    
      const mutationOptions = getDeleteApiProcessCalendarIdMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    export const putApiProcessCalendarId = (
    id: number,
    processDTOUpdate: ProcessDTOUpdate,
 options?: SecondParameter<typeof dotuAxiosInstance>,) => {
      return dotuAxiosInstance<CalendarProcessDTO>(
      {url: `/api/Process/Calendar/${id}`, method: 'put',
      headers: {'Content-Type': 'application/json-patch+json', },
      data: processDTOUpdate
    },
      options);
    }
  


export const getPutApiProcessCalendarIdMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putApiProcessCalendarId>>, TError,{id: number;data: ProcessDTOUpdate}, TContext>, request?: SecondParameter<typeof dotuAxiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof putApiProcessCalendarId>>, TError,{id: number;data: ProcessDTOUpdate}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putApiProcessCalendarId>>, {id: number;data: ProcessDTOUpdate}> = (props) => {
          const {id,data} = props ?? {};

          return  putApiProcessCalendarId(id,data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type PutApiProcessCalendarIdMutationResult = NonNullable<Awaited<ReturnType<typeof putApiProcessCalendarId>>>
    export type PutApiProcessCalendarIdMutationBody = ProcessDTOUpdate
    export type PutApiProcessCalendarIdMutationError = unknown

    export const usePutApiProcessCalendarId = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putApiProcessCalendarId>>, TError,{id: number;data: ProcessDTOUpdate}, TContext>, request?: SecondParameter<typeof dotuAxiosInstance>}
) => {
    
      const mutationOptions = getPutApiProcessCalendarIdMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    export const getApiProcessCalendarId = (
    id: number,
 options?: SecondParameter<typeof dotuAxiosInstance>,signal?: AbortSignal
) => {
      return dotuAxiosInstance<CalendarProcessDTO>(
      {url: `/api/Process/Calendar/${id}`, method: 'get', signal
    },
      options);
    }
  

export const getGetApiProcessCalendarIdQueryKey = (id: number,) => [`/api/Process/Calendar/${id}`] as const;
  

    
export const getGetApiProcessCalendarIdQueryOptions = <TData = Awaited<ReturnType<typeof getApiProcessCalendarId>>, TError = unknown>(id: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiProcessCalendarId>>, TError, TData>, request?: SecondParameter<typeof dotuAxiosInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof getApiProcessCalendarId>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiProcessCalendarIdQueryKey(id);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiProcessCalendarId>>> = ({ signal }) => getApiProcessCalendarId(id, requestOptions, signal);
    
      
      
   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions}}

export type GetApiProcessCalendarIdQueryResult = NonNullable<Awaited<ReturnType<typeof getApiProcessCalendarId>>>
export type GetApiProcessCalendarIdQueryError = unknown

export const useGetApiProcessCalendarId = <TData = Awaited<ReturnType<typeof getApiProcessCalendarId>>, TError = unknown>(
 id: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiProcessCalendarId>>, TError, TData>, request?: SecondParameter<typeof dotuAxiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetApiProcessCalendarIdQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}

export const putApiProcessCalendarReferenceId = (
    id: string,
    processDTOUpdate: ProcessDTOUpdate,
 options?: SecondParameter<typeof dotuAxiosInstance>,) => {
      return dotuAxiosInstance<CalendarProcessDTO[]>(
      {url: `/api/Process/Calendar/Reference/${id}`, method: 'put',
      headers: {'Content-Type': 'application/json-patch+json', },
      data: processDTOUpdate
    },
      options);
    }
  


export const getPutApiProcessCalendarReferenceIdMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putApiProcessCalendarReferenceId>>, TError,{id: string;data: ProcessDTOUpdate}, TContext>, request?: SecondParameter<typeof dotuAxiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof putApiProcessCalendarReferenceId>>, TError,{id: string;data: ProcessDTOUpdate}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putApiProcessCalendarReferenceId>>, {id: string;data: ProcessDTOUpdate}> = (props) => {
          const {id,data} = props ?? {};

          return  putApiProcessCalendarReferenceId(id,data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type PutApiProcessCalendarReferenceIdMutationResult = NonNullable<Awaited<ReturnType<typeof putApiProcessCalendarReferenceId>>>
    export type PutApiProcessCalendarReferenceIdMutationBody = ProcessDTOUpdate
    export type PutApiProcessCalendarReferenceIdMutationError = unknown

    export const usePutApiProcessCalendarReferenceId = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putApiProcessCalendarReferenceId>>, TError,{id: string;data: ProcessDTOUpdate}, TContext>, request?: SecondParameter<typeof dotuAxiosInstance>}
) => {
    
      const mutationOptions = getPutApiProcessCalendarReferenceIdMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    export const getApiProcessCalendarFast = (
    params?: GetApiProcessCalendarFastParams,
 options?: SecondParameter<typeof dotuAxiosInstance>,signal?: AbortSignal
) => {
      return dotuAxiosInstance<CalendarFastResponseDTO>(
      {url: `/api/Process/Calendar/Fast`, method: 'get',
        params, signal
    },
      options);
    }
  

export const getGetApiProcessCalendarFastQueryKey = (params?: GetApiProcessCalendarFastParams,) => [`/api/Process/Calendar/Fast`, ...(params ? [params]: [])] as const;
  

    
export const getGetApiProcessCalendarFastQueryOptions = <TData = Awaited<ReturnType<typeof getApiProcessCalendarFast>>, TError = unknown>(params?: GetApiProcessCalendarFastParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiProcessCalendarFast>>, TError, TData>, request?: SecondParameter<typeof dotuAxiosInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof getApiProcessCalendarFast>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiProcessCalendarFastQueryKey(params);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiProcessCalendarFast>>> = ({ signal }) => getApiProcessCalendarFast(params, requestOptions, signal);
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type GetApiProcessCalendarFastQueryResult = NonNullable<Awaited<ReturnType<typeof getApiProcessCalendarFast>>>
export type GetApiProcessCalendarFastQueryError = unknown

export const useGetApiProcessCalendarFast = <TData = Awaited<ReturnType<typeof getApiProcessCalendarFast>>, TError = unknown>(
 params?: GetApiProcessCalendarFastParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiProcessCalendarFast>>, TError, TData>, request?: SecondParameter<typeof dotuAxiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetApiProcessCalendarFastQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}

export const iCalDownloadUrl = (
    
 options?: SecondParameter<typeof dotuAxiosInstance>,signal?: AbortSignal
) => {
      return dotuAxiosInstance<string>(
      {url: `/api/Process/Calendar/DownloadUrl`, method: 'get', signal
    },
      options);
    }
  

export const getICalDownloadUrlQueryKey = () => [`/api/Process/Calendar/DownloadUrl`] as const;
  

    
export const getICalDownloadUrlQueryOptions = <TData = Awaited<ReturnType<typeof iCalDownloadUrl>>, TError = unknown>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof iCalDownloadUrl>>, TError, TData>, request?: SecondParameter<typeof dotuAxiosInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof iCalDownloadUrl>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getICalDownloadUrlQueryKey();

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof iCalDownloadUrl>>> = ({ signal }) => iCalDownloadUrl(requestOptions, signal);
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type ICalDownloadUrlQueryResult = NonNullable<Awaited<ReturnType<typeof iCalDownloadUrl>>>
export type ICalDownloadUrlQueryError = unknown

export const useICalDownloadUrl = <TData = Awaited<ReturnType<typeof iCalDownloadUrl>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof iCalDownloadUrl>>, TError, TData>, request?: SecondParameter<typeof dotuAxiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getICalDownloadUrlQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}

export const calendarICal = (
    params?: CalendarICalParams,
 options?: SecondParameter<typeof dotuAxiosInstance>,signal?: AbortSignal
) => {
      return dotuAxiosInstance<CalendarServiceICalResponseDto>(
      {url: `/api/Process/Calendar/ics`, method: 'get',
        params, signal
    },
      options);
    }
  

export const getCalendarICalQueryKey = (params?: CalendarICalParams,) => [`/api/Process/Calendar/ics`, ...(params ? [params]: [])] as const;
  

    
export const getCalendarICalQueryOptions = <TData = Awaited<ReturnType<typeof calendarICal>>, TError = unknown>(params?: CalendarICalParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof calendarICal>>, TError, TData>, request?: SecondParameter<typeof dotuAxiosInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof calendarICal>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getCalendarICalQueryKey(params);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof calendarICal>>> = ({ signal }) => calendarICal(params, requestOptions, signal);
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type CalendarICalQueryResult = NonNullable<Awaited<ReturnType<typeof calendarICal>>>
export type CalendarICalQueryError = unknown

export const useCalendarICal = <TData = Awaited<ReturnType<typeof calendarICal>>, TError = unknown>(
 params?: CalendarICalParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof calendarICal>>, TError, TData>, request?: SecondParameter<typeof dotuAxiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getCalendarICalQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}

/**
 * @summary Process - Write
 */
export const postApiProcessTour = (
    processDTOCreate: ProcessDTOCreate,
    params?: PostApiProcessTourParams,
 options?: SecondParameter<typeof dotuAxiosInstance>,) => {
      return dotuAxiosInstance<ProcessDTO>(
      {url: `/api/Process/Tour`, method: 'post',
      headers: {'Content-Type': 'application/json-patch+json', },
      data: processDTOCreate,
        params
    },
      options);
    }
  


export const getPostApiProcessTourMutationOptions = <TError = ErrorResponse,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProcessTour>>, TError,{data: ProcessDTOCreate;params?: PostApiProcessTourParams}, TContext>, request?: SecondParameter<typeof dotuAxiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof postApiProcessTour>>, TError,{data: ProcessDTOCreate;params?: PostApiProcessTourParams}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiProcessTour>>, {data: ProcessDTOCreate;params?: PostApiProcessTourParams}> = (props) => {
          const {data,params} = props ?? {};

          return  postApiProcessTour(data,params,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type PostApiProcessTourMutationResult = NonNullable<Awaited<ReturnType<typeof postApiProcessTour>>>
    export type PostApiProcessTourMutationBody = ProcessDTOCreate
    export type PostApiProcessTourMutationError = ErrorResponse

    /**
 * @summary Process - Write
 */
export const usePostApiProcessTour = <TError = ErrorResponse,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProcessTour>>, TError,{data: ProcessDTOCreate;params?: PostApiProcessTourParams}, TContext>, request?: SecondParameter<typeof dotuAxiosInstance>}
) => {
    
      const mutationOptions = getPostApiProcessTourMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    /**
 * @summary Process - Write
 */
export const putApiProcessTourId = (
    id: number,
    processDTOUpdate: ProcessDTOUpdate,
    params?: PutApiProcessTourIdParams,
 options?: SecondParameter<typeof dotuAxiosInstance>,) => {
      return dotuAxiosInstance<ProcessDTO>(
      {url: `/api/Process/Tour/${id}`, method: 'put',
      headers: {'Content-Type': 'application/json-patch+json', },
      data: processDTOUpdate,
        params
    },
      options);
    }
  


export const getPutApiProcessTourIdMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putApiProcessTourId>>, TError,{id: number;data: ProcessDTOUpdate;params?: PutApiProcessTourIdParams}, TContext>, request?: SecondParameter<typeof dotuAxiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof putApiProcessTourId>>, TError,{id: number;data: ProcessDTOUpdate;params?: PutApiProcessTourIdParams}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putApiProcessTourId>>, {id: number;data: ProcessDTOUpdate;params?: PutApiProcessTourIdParams}> = (props) => {
          const {id,data,params} = props ?? {};

          return  putApiProcessTourId(id,data,params,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type PutApiProcessTourIdMutationResult = NonNullable<Awaited<ReturnType<typeof putApiProcessTourId>>>
    export type PutApiProcessTourIdMutationBody = ProcessDTOUpdate
    export type PutApiProcessTourIdMutationError = unknown

    /**
 * @summary Process - Write
 */
export const usePutApiProcessTourId = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putApiProcessTourId>>, TError,{id: number;data: ProcessDTOUpdate;params?: PutApiProcessTourIdParams}, TContext>, request?: SecondParameter<typeof dotuAxiosInstance>}
) => {
    
      const mutationOptions = getPutApiProcessTourIdMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    /**
 * @summary Process - Write
 */
export const deleteApiProcessTourId = (
    id: number,
 options?: SecondParameter<typeof dotuAxiosInstance>,) => {
      return dotuAxiosInstance<void>(
      {url: `/api/Process/Tour/${id}`, method: 'delete'
    },
      options);
    }
  


export const getDeleteApiProcessTourIdMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteApiProcessTourId>>, TError,{id: number}, TContext>, request?: SecondParameter<typeof dotuAxiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteApiProcessTourId>>, TError,{id: number}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteApiProcessTourId>>, {id: number}> = (props) => {
          const {id} = props ?? {};

          return  deleteApiProcessTourId(id,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type DeleteApiProcessTourIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteApiProcessTourId>>>
    
    export type DeleteApiProcessTourIdMutationError = unknown

    /**
 * @summary Process - Write
 */
export const useDeleteApiProcessTourId = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteApiProcessTourId>>, TError,{id: number}, TContext>, request?: SecondParameter<typeof dotuAxiosInstance>}
) => {
    
      const mutationOptions = getDeleteApiProcessTourIdMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    /**
 * @summary Process - Write
 */
export const deleteApiProcessRecurring = (
    params?: DeleteApiProcessRecurringParams,
 options?: SecondParameter<typeof dotuAxiosInstance>,) => {
      return dotuAxiosInstance<void>(
      {url: `/api/Process/Recurring`, method: 'delete',
        params
    },
      options);
    }
  


export const getDeleteApiProcessRecurringMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteApiProcessRecurring>>, TError,{params?: DeleteApiProcessRecurringParams}, TContext>, request?: SecondParameter<typeof dotuAxiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteApiProcessRecurring>>, TError,{params?: DeleteApiProcessRecurringParams}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteApiProcessRecurring>>, {params?: DeleteApiProcessRecurringParams}> = (props) => {
          const {params} = props ?? {};

          return  deleteApiProcessRecurring(params,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type DeleteApiProcessRecurringMutationResult = NonNullable<Awaited<ReturnType<typeof deleteApiProcessRecurring>>>
    
    export type DeleteApiProcessRecurringMutationError = unknown

    /**
 * @summary Process - Write
 */
export const useDeleteApiProcessRecurring = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteApiProcessRecurring>>, TError,{params?: DeleteApiProcessRecurringParams}, TContext>, request?: SecondParameter<typeof dotuAxiosInstance>}
) => {
    
      const mutationOptions = getDeleteApiProcessRecurringMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    