import { faCommentLines } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import LogoCircle from 'assets/images/logo/LogoCircle.svg'
import LogoText from 'assets/images/logo/LogoText.svg'
import { ICallModalContextProvider } from 'components/modals/linkModal/LinkModalContext'
import { LINKS } from 'constants/links'
import { useTranslation } from 'next-i18next'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { PropsWithChildren, useState } from 'react'
import { PRIMARY_COLOR } from 'styles/theme'
import { Layout } from '../Layout'
import { useCalendarPageLink } from './LayoutAppUtils'
import { FeedbackModal } from './feedbackModal/FeedbackModal'
import { LayoutAppMenu } from './menu/LayoutAppMenu'
import { LayoutAppUserMenu } from './userMenu/LayoutAppUserMenu'

export function LayoutApp({ children }: PropsWithChildren) {
  const calendarPageLink = useCalendarPageLink()

  const router = useRouter()

  const { t } = useTranslation(['app'])

  const { breakpoints } = useTheme()
  const isSmallDevice = useMediaQuery(breakpoints.down('sm'))
  const isMediumDevice = useMediaQuery(breakpoints.down('md'))
  const isLargeDevice = useMediaQuery(breakpoints.down('lg'))

  const [isFeedbackModalOpen, setFeedbackModalOpen] = useState(false)

  return (
    <ICallModalContextProvider>
      <Layout>
        <Paper elevation={isSmallDevice ? 4 : 3} square>
          <Container maxWidth="xl">
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              paddingY={{ xs: 1.5, sm: 2.5 }}
              height="100%"
            >
              <Link href={calendarPageLink} aria-label="dotu calendar">
                <Stack direction="row" alignItems="center" gap={1.5}>
                  <LogoCircle
                    height={isSmallDevice ? 28 : 32}
                    style={{ fill: PRIMARY_COLOR }}
                  />
                  {!isMediumDevice && (
                    <LogoText height={21} style={{ fill: PRIMARY_COLOR }} />
                  )}
                </Stack>
              </Link>

              <LayoutAppMenu />

              <LayoutAppUserMenu />
            </Stack>
          </Container>
        </Paper>

        <Container maxWidth="xl">
          <Box
            paddingTop={{ xs: 3.5, sm: 7 }}
            paddingBottom={{ xs: 9, sm: 18 }}
          >
            {children}
          </Box>
        </Container>

        {(!isLargeDevice ||
          (isLargeDevice && router.pathname !== LINKS.app)) && (
          <Button
            variant="contained"
            aria-label={t('app:feedback.title')}
            onClick={() => setFeedbackModalOpen(true)}
            sx={{
              zIndex: 100,
              position: 'fixed',
              left: isSmallDevice ? 8 : 16,
              bottom: isSmallDevice ? 8 : 16,
              minWidth: isSmallDevice ? 32 : 40,
              maxWidth: isSmallDevice ? 32 : 40,
              minHeight: isSmallDevice ? 32 : 40,
              maxHeight: isSmallDevice ? 32 : 40,
              borderRadius: '100%',
              p: 0
            }}
          >
            <FontAwesomeIcon icon={faCommentLines} fontSize="1rem" />
          </Button>
        )}

        <FeedbackModal
          isOpened={isFeedbackModalOpen}
          onClose={() => setFeedbackModalOpen(false)}
        />
      </Layout>
    </ICallModalContextProvider>
  )
}
