function stringToColor(string: string) {
  let hash = 0
  let i

  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash)
  }

  let color = '#'

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff
    color += `00${value.toString(16)}`.slice(-2)
  }

  return color
}

export function stringAvatar(
  name: string,
  center?: boolean,
  primary?: boolean,
  size = 40
) {
  const firstName = name.split(' ')[0] ? name.split(' ')[0][0] : ''
  const lastName = name.split(' ')[1] ? name.split(' ')[1][0] : ''
  const fontSize = (size / 40) * 18

  return {
    sx: {
      bgcolor: stringToColor(name),
      width: size,
      height: size,
      lineHeight: size,
      fontSize,
      ...(center && {
        margin: '0 auto'
      }),
      ...(primary && {
        bgcolor: 'primary.main'
      })
    },
    children: `${firstName}${lastName}`
  }
}
