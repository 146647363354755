import Link from '@mui/material/Link'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { ModalBase } from 'components/modals/ModalBase'
import { useTranslation } from 'next-i18next'

interface FeedbackModalProps {
  isOpened: boolean
  onClose: () => void
}

export function FeedbackModal({ isOpened, onClose }: FeedbackModalProps) {
  const { t } = useTranslation(['app'])

  return (
    <ModalBase isOpened={isOpened} onClose={onClose} sx={{ pt: 0 }}>
      <Stack textAlign="center" gap={1}>
        <Typography variant="h5">{t('app:feedback.title')}</Typography>
        <Typography>
          {t('app:feedback.subtitle')}{' '}
          <Link
            href={`mailto:calendar@dotu.cz?subject=💬 ${t(
              'app:feedback.title'
            )}`}
          >
            calendar@dotu.cz
          </Link>
        </Typography>
      </Stack>
    </ModalBase>
  )
}
