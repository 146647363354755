import type { ReactElement } from 'react'

import InputAdornment from '@mui/material/InputAdornment'
import Skeleton from '@mui/material/Skeleton'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material/styles'

import { faCalendar, faCopy } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { Input } from 'components/form/Input'

import { useICalDownloadUrl } from 'api/generated/dotu/process'
import { useTranslation } from 'next-i18next'

import Link from '@mui/material/Link'
import toast from 'react-hot-toast'
import { PRIMARY_COLOR } from 'styles/theme'

export function LinkModalIcal(): ReactElement {
  const { data: link, isLoading, isError, isSuccess } = useICalDownloadUrl()

  const { typography } = useTheme()
  const { t } = useTranslation(['app'])

  const copyToClipboard = (text: string) => {
    try {
      navigator.clipboard.writeText(text)
      toast.success(t('app:linkModal.actions.copyToClipboard.success'))
    } catch {
      toast.error(t('app:linkModal.actions.copyToClipboard.error'))
    }
  }

  return (
    <Stack
      sx={{
        display: 'flex',
        position: 'relative',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center'
      }}
      gap={3}
    >
      <Stack
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 1
        }}
      >
        <FontAwesomeIcon icon={faCalendar} size="6x" color={PRIMARY_COLOR} />
        <Typography mt={1} variant="h5">
          {t('app:linkModal.ical.title')}
        </Typography>
        <Typography
          variant="subtitle2"
          sx={{
            textAlign: 'center',
            opacity: typography.opacity.medium
          }}
        >
          {t('app:linkModal.ical.subTitle')}
        </Typography>
      </Stack>

      {isLoading && <Skeleton width="100%" height={56} />}

      {isSuccess && (
        <Input
          fullWidth
          name="url"
          label={t('app:linkModal.ical.inputLabel')}
          value={link}
          readOnly
          onClick={() => copyToClipboard(link)}
          cursor={'copy' as 'text'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <FontAwesomeIcon icon={faCopy} />
              </InputAdornment>
            )
          }}
        />
      )}

      {isError && (
        <Typography>{t('app:linkModal.ical.linkLoadingError')}</Typography>
      )}

      <Stack alignSelf="start" alignItems="start" gap={0.5}>
        <Typography fontWeight="700">
          {t('app:linkModal.ical.howToLinkTitle')}
        </Typography>
        <Link
          href="https://support.apple.com/guide/iphone/iph3d1110d4/ios"
          target="_blank"
          variant="body2"
        >
          iOS
        </Link>
        <Link
          href="https://support.google.com/calendar/answer/37100"
          target="_blank"
          variant="body2"
        >
          Google Calendar
        </Link>
        <Link
          href="https://napoveda.seznam.cz/cz/kalendar/kalendar-pro-mobilni-zarizeni/ical-na-ios/"
          target="_blank"
          variant="body2"
        >
          Seznam Kalendář (iOS)
        </Link>
        <Link
          href="https://napoveda.seznam.cz/cz/kalendar/kalendar-pro-mobilni-zarizeni/ical-na-androidu/"
          target="_blank"
          variant="body2"
        >
          Seznam Kalendář (Android)
        </Link>
      </Stack>
    </Stack>
  )
}
