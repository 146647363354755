import { getTimeZones, TimeZone } from '@vvo/tzdb'
import { CalendarEvent } from 'api/dotu/calendar/calendarList.utils'
import { createContext, PropsWithChildren, useContext, useState } from 'react'
import { localStorageClient } from './dom'

const timeZones = getTimeZones()

export const getTimeZoneOffsetLabel = (timeZone: TimeZone) => {
  return timeZone.currentTimeFormat.slice(0, 6)
}

export const DEFAULT_TIMEZONE =
  timeZones.find(timeZone => timeZone.name === 'Europe/Prague') ?? timeZones[0]

interface EventsContextValue {
  displayedTimeZone?: TimeZone
  changeTimeZone: (timeZone?: TimeZone) => void
}

const TimeZoneContext = createContext<EventsContextValue>({
  displayedTimeZone: DEFAULT_TIMEZONE,
  changeTimeZone: () => undefined
})

export function TimeZoneProvider({ children }: PropsWithChildren) {
  const localStorageTimeZone = localStorageClient.getItem('displayedTimeZone')
  const defaultTimeZone = timeZones.find(
    timezone => timezone.name === localStorageTimeZone
  )

  const [timeZone, setTimeZone] = useState<TimeZone | undefined>(
    defaultTimeZone
  )

  const changeTimeZone = (timeZone?: TimeZone) => {
    setTimeZone(timeZone)
    localStorageClient.setItem('displayedTimeZone', timeZone?.name ?? '')
  }

  return (
    <TimeZoneContext.Provider
      value={{
        displayedTimeZone: timeZone,
        changeTimeZone
      }}
    >
      {children}
    </TimeZoneContext.Provider>
  )
}

export function useTimeZone() {
  const context = useContext(TimeZoneContext)
  if (!context) {
    throw new Error('useTimeZone must be used within a TimeZoneContext')
  }
  return context
}

export const useEventTimeZone = () => {
  const { displayedTimeZone } = useTimeZone()

  return (event?: CalendarEvent) => {
    let timeZone = DEFAULT_TIMEZONE.name

    if (!event) {
      return timeZone
    }

    if (displayedTimeZone) {
      timeZone = displayedTimeZone.name
    } else if (event.timeZone) {
      timeZone = event.timeZone
    }

    return timeZone
  }
}
