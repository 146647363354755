import { faCalendar, faUsers } from '@fortawesome/pro-regular-svg-icons'
import { LINKS } from 'constants/links'
import { useTranslation } from 'next-i18next'
import { useCalendarPageLink } from '../LayoutAppUtils'

export const useMenuItems = () => {
  const { t } = useTranslation('app')
  const calendarPageLink = useCalendarPageLink()

  return [
    {
      icon: faCalendar,
      label: t('mainMenu.calendar'),
      baseUrl: LINKS.app,
      link: calendarPageLink
    },
    {
      icon: faUsers,
      label: t('mainMenu.accounts'),
      baseUrl: LINKS.appAccounts,
      link: LINKS.appAccounts
    }
  ]
}
