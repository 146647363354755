/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * Planner API
 * OpenAPI spec version: v1
 */
import {
  useQuery
} from '@tanstack/react-query'
import type {
  UseQueryOptions,
  QueryFunction,
  UseQueryResult,
  QueryKey
} from '@tanstack/react-query'
import type {
  CalendarStartDTO
} from './dotu.schemas'
import { dotuAxiosInstance } from '../../axios';



// eslint-disable-next-line
  type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

export const getApiStartCalendar = (
    
 options?: SecondParameter<typeof dotuAxiosInstance>,signal?: AbortSignal
) => {
      return dotuAxiosInstance<CalendarStartDTO>(
      {url: `/api/Start/Calendar`, method: 'get', signal
    },
      options);
    }
  

export const getGetApiStartCalendarQueryKey = () => [`/api/Start/Calendar`] as const;
  

    
export const getGetApiStartCalendarQueryOptions = <TData = Awaited<ReturnType<typeof getApiStartCalendar>>, TError = unknown>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiStartCalendar>>, TError, TData>, request?: SecondParameter<typeof dotuAxiosInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof getApiStartCalendar>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiStartCalendarQueryKey();

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiStartCalendar>>> = ({ signal }) => getApiStartCalendar(requestOptions, signal);
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type GetApiStartCalendarQueryResult = NonNullable<Awaited<ReturnType<typeof getApiStartCalendar>>>
export type GetApiStartCalendarQueryError = unknown

export const useGetApiStartCalendar = <TData = Awaited<ReturnType<typeof getApiStartCalendar>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiStartCalendar>>, TError, TData>, request?: SecondParameter<typeof dotuAxiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetApiStartCalendarQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}

