interface GoogleCalendarIconProps {
  size?: number
}

export function GoogleCalendar({ size = 29 }: GoogleCalendarIconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 29 29" fill="none">
      <g clipPath="url(#clip0_386_50)">
        <path
          d="M22.1319 6.86836L15.2635 6.10522L6.86875 6.86836L6.10547 14.5L6.8686 22.1316L14.5002 23.0856L22.1319 22.1316L22.895 14.3093L22.1319 6.86836Z"
          fill="white"
        />
        <path
          d="M9.99743 18.7089C9.427 18.3235 9.03202 17.7607 8.81641 17.0166L10.1405 16.4709C10.2608 16.9288 10.4706 17.2837 10.7701 17.5355C11.0678 17.7874 11.4303 17.9114 11.8539 17.9114C12.287 17.9114 12.6591 17.7797 12.9699 17.5164C13.2808 17.2531 13.4374 16.9172 13.4374 16.511C13.4374 16.0951 13.2733 15.7554 12.9451 15.4922C12.617 15.229 12.2049 15.0972 11.7126 15.0972H10.9476V13.7866H11.6343C12.0579 13.7866 12.4147 13.6721 12.7047 13.4432C12.9947 13.2142 13.1397 12.9013 13.1397 12.5026C13.1397 12.1478 13.01 11.8653 12.7505 11.6536C12.4911 11.4419 12.1629 11.335 11.7641 11.335C11.3749 11.335 11.0658 11.4381 10.8368 11.6461C10.6079 11.854 10.4419 12.1096 10.337 12.4111L9.02637 11.8654C9.19993 11.3732 9.51864 10.9382 9.98598 10.5623C10.4535 10.1865 11.0506 9.99756 11.7756 9.99756C12.3116 9.99756 12.7943 10.1007 13.2218 10.3086C13.6491 10.5165 13.9849 10.8046 14.2272 11.1709C14.4695 11.5391 14.5897 11.9513 14.5897 12.4091C14.5897 12.8765 14.4772 13.2714 14.252 13.5957C14.0268 13.9201 13.7502 14.168 13.422 14.3418V14.4199C13.8552 14.6012 14.2081 14.8778 14.4866 15.2499C14.7633 15.622 14.9025 16.0665 14.9025 16.5855C14.9025 17.1044 14.7708 17.568 14.5075 17.9744C14.2442 18.3809 13.8798 18.7013 13.4181 18.9341C12.9546 19.1668 12.4337 19.2851 11.8556 19.2851C11.186 19.287 10.5679 19.0943 9.99743 18.7089Z"
          fill="#1A73E8"
        />
        <path
          d="M18.1262 12.138L16.68 13.1893L15.9531 12.0866L18.5612 10.2053H19.561V19.0789H18.1262V12.138Z"
          fill="#1A73E8"
        />
        <path
          d="M22.1317 29.0001L29.0001 22.1317L25.5659 20.6055L22.1317 22.1317L20.6055 25.5659L22.1317 29.0001Z"
          fill="#EA4335"
        />
        <path
          d="M5.34375 25.5658L6.87002 29H22.1332V22.1316H6.87002L5.34375 25.5658Z"
          fill="#34A853"
        />
        <path
          d="M2.28941 0C1.02457 0 0 1.02457 0 2.28941V22.1315L3.43418 23.6578L6.86836 22.1315V6.86836H22.1315L23.6578 3.43418L22.1316 0H2.28941Z"
          fill="#4285F4"
        />
        <path
          d="M0 22.1316V26.7105C0 27.9755 1.02457 29 2.2894 29H6.86836V22.1316H0Z"
          fill="#188038"
        />
        <path
          d="M22.1328 6.86831V22.1314H29.0012V6.86831L25.567 5.34204L22.1328 6.86831Z"
          fill="#FBBC04"
        />
        <path
          d="M29.0012 6.86836V2.2894C29.0012 1.02442 27.9766 0 26.7118 0H22.1328V6.86836H29.0012Z"
          fill="#1967D2"
        />
      </g>
      <defs>
        <clipPath id="clip0_386_50">
          <rect width="29" height="29" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
