import { QueryKey, UseQueryOptions } from '@tanstack/react-query'
import { CalendarFastResponseDTO } from 'api/generated/dotu/dotu.schemas'
import { useGetApiProcessCalendarFast } from 'api/generated/dotu/process'
import { useGetApiStartCalendar } from 'api/generated/dotu/start'
import { CALENDAR_DATA_REVALIDATE_TIME } from 'constants/hydrate'
import { addDays, endOfDay, startOfDay } from 'date-fns'
import { refetchQueries } from 'utils/queries'
import { resolveCalendarData } from './calendarList.utils'

export function useCalendarListQuery(
  startDate?: Date,
  endDate?: Date,
  processStatuses?: number[],
  castStatuses?: number[],
  refetchInterval?: number | false,
  options?: UseQueryOptions<
    CalendarFastResponseDTO,
    unknown,
    CalendarFastResponseDTO,
    QueryKey
  >
) {
  // load all entities to map data from calendar list
  const startQuery = useGetApiStartCalendar()

  // load calendar list data
  const calendarQuery = useGetApiProcessCalendarFast(
    {
      from: startDate
        ? addDays(startOfDay(startDate), -1)?.toISOString()
        : undefined,
      to: endDate ? addDays(endOfDay(endDate), 1)?.toISOString() : undefined,
      status: processStatuses,
      // temporary fix for typescript error
      castStatus: castStatuses ? (2 as unknown as number[]) : undefined
    },
    {
      query: {
        refetchInterval: refetchInterval ?? CALENDAR_DATA_REVALIDATE_TIME,
        enabled: !!startDate,
        ...options
      }
    }
  )

  const queries = [startQuery, calendarQuery]

  const data = resolveCalendarData(calendarQuery.data, startQuery.data)

  return {
    data,
    isLoading: queries.some(query => query.isLoading),
    isError: queries.some(query => query.isError),
    isSuccess: queries.every(query => query.isSuccess),
    isFetching: queries.some(query => query.isFetching),
    refetch: () => refetchQueries(queries)
  }
}
