import type { ReactElement } from 'react'

import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

import { GoogleCalendar } from 'assets/images/GoogleCalendar'

import { useTheme } from '@mui/material/styles'
import { useTranslation } from 'next-i18next'

export function LinkModalGoogle(): ReactElement {
  const { t } = useTranslation(['app'])
  const { typography } = useTheme()

  return (
    <Stack alignItems="center" gap={2}>
      <GoogleCalendar size={77} />

      <Typography variant="h5" textAlign="center">
        {t('app:linkModal.google.title')}
      </Typography>
      <Typography
        variant="subtitle2"
        mt={-1.5}
        sx={{ textAlign: 'center', opacity: typography.opacity.medium }}
      >
        {t('app:linkModal.google.subTitle')}
      </Typography>
    </Stack>
  )
}
