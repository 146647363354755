import { useTheme } from '@mui/material/styles'

export type ProcessStatus = 'PENDING' | 'DECLINED' | 'CONFIRMED' | 'HIDDEN'

export const getProcessStatus = (status?: number): ProcessStatus => {
  switch (status) {
    case 1:
    case 2:
    case 4:
      return 'PENDING'
    case 3:
      return 'DECLINED'
    case 5:
      return 'CONFIRMED'
    case 0:
    default:
      return 'HIDDEN'
  }
}

export type CastStatus =
  | 'PENDING'
  | 'DECLINED'
  | 'ACCEPTED'
  | 'CONFIRMED'
  | 'HIDDEN'

export const getCastStatus = (status?: number): CastStatus => {
  switch (status) {
    case 2:
      return 'PENDING'
    case 3:
      return 'DECLINED'
    case 4:
      return 'ACCEPTED'
    case 5:
      return 'CONFIRMED'
    default:
      return 'HIDDEN'
  }
}

export const useProcessColor = (processStatus: ProcessStatus) => {
  const { palette } = useTheme()

  switch (processStatus) {
    case 'PENDING':
      return palette.processStatus.pending
    case 'DECLINED':
      return palette.processStatus.declined
    case 'CONFIRMED':
      return palette.processStatus.confirmed
    default:
      return palette.processStatus.personal
  }
}
