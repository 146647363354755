import { LINKS } from 'constants/links'
import { useDateFns } from 'utils/date'
import { localStorageClient } from 'utils/dom'

const now = new Date()

export const useCalendarPageLink = () => {
  const savedParams = localStorageClient.getItem('calendarParams')

  const dateFns = useDateFns()

  let params
  const jsonParams = savedParams ? JSON.parse(savedParams) : null
  if (jsonParams?.start && jsonParams?.end) {
    params = {
      start: jsonParams.start,
      end: jsonParams.end,
      view: jsonParams.view || 'agenda'
    }
  } else {
    params = {
      start: dateFns.startOfMonth(now).setHours(0, 0, 0, 0),
      end: dateFns.endOfMonth(now).setHours(0, 0, 0, 0),
      view: 'agenda'
    }
  }
  return LINKS.appParams(params.start, params.end, params.view)
}
