import { faArrowLeftLong, faCheck } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import MenuItem from '@mui/material/MenuItem'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'

interface LayoutAppUserMenuPopupLanguageProps {
  onClose: () => void
  onBackClick: () => void
}

export function LayoutAppUserMenuPopupLanguage({
  onClose,
  onBackClick
}: LayoutAppUserMenuPopupLanguageProps) {
  const { t } = useTranslation(['app'])

  const {
    pathname,
    asPath,
    query,
    push,
    locale: activeLocale,
    locales
  } = useRouter()

  const handleLocaleChange = (locale: string) => {
    onClose()

    if (locale !== activeLocale) {
      push({ pathname, query }, asPath, {
        locale
      })
    }
  }

  const getLocaleLabel = (locale: string) => {
    if (locale === 'cs') {
      return t('app:userMenu.locales.cs')
    } else if (locale === 'en') {
      return t('app:userMenu.locales.en')
    }
    return null
  }

  return (
    <>
      <Stack direction="row" alignItems="center" gap={0.5} paddingX={1}>
        <IconButton onClick={onBackClick}>
          <FontAwesomeIcon icon={faArrowLeftLong} fontSize="16" />
        </IconButton>
        <Typography fontWeight="medium" color="text.secondary">
          {t('app:userMenu.language')}
        </Typography>
      </Stack>

      {locales?.map(locale => (
        <MenuItem key={locale} onClick={() => handleLocaleChange(locale)}>
          <ListItemIcon
            sx={{
              color: 'primary.main'
            }}
          >
            {locale === activeLocale ? (
              <FontAwesomeIcon icon={faCheck} />
            ) : (
              <Box width="14px" />
            )}
          </ListItemIcon>
          <ListItemText
            primaryTypographyProps={{
              color: locale === activeLocale ? 'primary' : 'text.secondary'
            }}
          >
            {getLocaleLabel(locale) ?? locale.toUpperCase()}
          </ListItemText>
        </MenuItem>
      ))}
    </>
  )
}
