import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'

export function LinkModalChoiceButton({
  children,
  onClick
}: {
  children: React.ReactNode
  onClick: () => void
}) {
  return (
    <Button
      fullWidth
      variant="outlined"
      size="large"
      sx={{
        p: 2.5,
        borderWidth: '2px',

        '&:hover': {
          borderWidth: '2px'
        }
      }}
      onClick={onClick}
    >
      <Stack
        width="100%"
        direction="row"
        justifyItems="start"
        alignItems="center"
        gap={2}
      >
        {children}
      </Stack>
    </Button>
  )
}
