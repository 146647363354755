import { faChevronLeft, faClose } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import Modal from '@mui/material/Modal'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import { SxProps, useTheme } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import useMediaQuery from '@mui/material/useMediaQuery'
import { Theme } from '@mui/system/createTheme'
import { PropsWithChildren } from 'react'
import { useMeasure } from 'react-use'

interface ModalBaseProps {
  isOpened: boolean
  onClose?: () => void
  title?: string
  variant?: 'x-large' | 'large' | 'medium' | 'small'
  sx?: SxProps<Theme>
  onBack?: () => void
}

export function ModalBase({
  children,
  isOpened,
  onClose,
  title,
  variant = 'medium',
  sx,
  onBack
}: PropsWithChildren<ModalBaseProps>) {
  const [ref, { height }] = useMeasure()

  const { breakpoints } = useTheme()
  const isSmallDevice = useMediaQuery(breakpoints.down('sm'))

  const getWidth = () => {
    if (variant === 'x-large') {
      return 750
    } else if (variant === 'large') {
      return 600
    } else {
      return 500
    }
  }

  return (
    <Modal
      open={isOpened}
      onClose={onClose}
      slotProps={{
        backdrop: { sx: { backdropFilter: 'blur(1px)' } }
      }}
    >
      <Paper
        elevation={24}
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: getWidth(),
          maxWidth: 'calc(100vw - 32px)',
          outline: 0,
          maxHeight: 'calc(100vh - 32px)',

          '&:focus': {
            outline: 0
          }
        }}
      >
        <Box ref={ref}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent={title || onBack ? 'space-between' : 'flex-end'}
            paddingX={isSmallDevice ? 2 : 4}
            paddingY={isSmallDevice ? 1 : 2}
          >
            {onBack && (
              <IconButton onClick={onBack} sx={{ width: 0.09 }}>
                <FontAwesomeIcon icon={faChevronLeft} />
              </IconButton>
            )}
            {title && <Typography variant="h4">{title}</Typography>}
            {onClose && (
              <IconButton onClick={onClose}>
                <FontAwesomeIcon icon={faClose} />
              </IconButton>
            )}
          </Stack>

          {title && <Divider />}
        </Box>

        <Box
          padding={isSmallDevice ? 3 : 4}
          paddingBottom={isSmallDevice ? 4 : 5}
          sx={{
            ...sx,
            overflow: 'auto',
            maxHeight: `calc(100vh - 32px - ${height}px)`
          }}
        >
          {children}
        </Box>
      </Paper>
    </Modal>
  )
}
