import type { ReactElement } from 'react'

import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material/styles'

import { faCalendar } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { GoogleCalendar } from 'assets/images/GoogleCalendar'

import { useTranslation } from 'next-i18next'
import { LinkModalChoiceButton } from './LinkModalChoiceButton'

interface LinkModalChoiceProps {
  onGoogleModal: () => void
  onIcalModal: () => void
}

export function LinkModalChoice({
  onGoogleModal,
  onIcalModal
}: LinkModalChoiceProps): ReactElement {
  const { t } = useTranslation(['app'])
  const { typography } = useTheme()

  return (
    <Stack gap={4} alignItems="center">
      <Stack flexDirection="column" alignItems="center" gap={0.5}>
        <Typography variant="h5" textAlign="center">
          {t('app:linkModal.choice.title')}
        </Typography>
        <Typography
          variant="subtitle2"
          textAlign="center"
          sx={{
            opacity: typography.opacity.medium
          }}
        >
          {t('app:linkModal.choice.subTitle')}
        </Typography>
      </Stack>

      <Stack width={{ xs: '100%', sm: '75%' }} gap={1.5}>
        {process.env.NEXT_PUBLIC_FEAT_GOOGLE_CALENDAR === 'true' && (
          <LinkModalChoiceButton onClick={onGoogleModal}>
            <GoogleCalendar />
            <Typography fontWeight="500" color="inherit">
              {t('app:linkModal.choice.googleButton')}
            </Typography>
          </LinkModalChoiceButton>
        )}

        <LinkModalChoiceButton onClick={onIcalModal}>
          <FontAwesomeIcon icon={faCalendar} size="2x" />
          <Typography fontWeight="500" color="inherit">
            {t('app:linkModal.choice.urlButton')}
          </Typography>
        </LinkModalChoiceButton>
      </Stack>
    </Stack>
  )
}
