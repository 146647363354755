import { faBars } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useMediaQuery } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import Skeleton from '@mui/material/Skeleton'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material/styles'
import { useGetApiSession } from 'api/generated/dotu/session'
import { Avatar } from 'components/avatar/Avatar'
import { useTranslation } from 'next-i18next'
import { useState } from 'react'
import { useLogout } from 'utils/auth/useLogout'
import { LayoutAppUserMenuPopup } from './LayoutAppUserMenuPopup'
import { LayoutAppUserMenuPopupLanguage } from './LayoutAppUserMenuPopupLanguage'

const USER_MENU_WIDTH = '220px'

const a11yProps = {
  button: 'user-menu-button',
  menu: `user-menu`
}

export function LayoutAppUserMenu() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const { t } = useTranslation(['app'])

  const logout = useLogout()

  const [isLanguageView, setIsLanguageView] = useState(false)

  const { palette, breakpoints } = useTheme()
  const isMediumDevice = useMediaQuery(breakpoints.down('md'))

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)

    setTimeout(() => {
      setIsLanguageView(false)
    }, 300)
  }

  const { data, isLoading } = useGetApiSession({
    query: {
      onError: () => {
        logout()
      }
    }
  })

  return (
    <>
      <Button
        variant="outlined"
        id={a11yProps.button}
        aria-controls={open ? a11yProps.menu : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleOpen}
        color="inherit"
        sx={{
          paddingY: 1,
          paddingX: { xs: 1, sm: 1.5 },
          color: 'text.secondary',
          borderColor: 'divider',
          width: isMediumDevice ? 'auto' : USER_MENU_WIDTH,
          alignItems: 'center',
          gap: { xs: 1, sm: 1.5 }
        }}
      >
        {isLoading ? (
          <Skeleton variant="circular" width="48px" height="48px" />
        ) : (
          <Avatar
            name={data?.user?.name ?? undefined}
            size={isMediumDevice ? 32 : 48}
            primary
            disableTooltip
          />
        )}

        {!isMediumDevice && (
          <Stack direction="column" alignItems="start" overflow="hidden">
            <Typography
              variant="body2"
              fontWeight="medium"
              whiteSpace="nowrap"
              textOverflow="ellipsis"
              overflow="hidden"
              maxWidth="100%"
            >
              {isLoading ? (
                <Skeleton variant="text" width="100px" />
              ) : (
                data?.user?.name
              )}
            </Typography>
            <Typography fontWeight="medium" variant="caption">
              {isLoading ? (
                <Skeleton variant="text" width="48px" />
              ) : (
                t('app:userMenu.user')
              )}
            </Typography>
          </Stack>
        )}

        <FontAwesomeIcon
          css={{ marginLeft: 'auto' }}
          icon={faBars}
          fontSize="16px"
          color={palette.text.secondary}
        />
      </Button>

      <Menu
        id={a11yProps.menu}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': a11yProps.button
        }}
      >
        <Box width={USER_MENU_WIDTH}>
          {isLanguageView ? (
            <LayoutAppUserMenuPopupLanguage
              onClose={handleClose}
              onBackClick={() => setIsLanguageView(false)}
            />
          ) : (
            <LayoutAppUserMenuPopup
              onClose={handleClose}
              onLanguageView={() => setIsLanguageView(true)}
            />
          )}
        </Box>
      </Menu>
    </>
  )
}
