import { useQueryClient } from '@tanstack/react-query'
import { useCalendarListQuery } from 'api/dotu/calendar/calendarList'
import {
  CalendarEventResource,
  ProcessEvent
} from 'api/dotu/calendar/calendarList.utils'
import { CalendarFastResponseDTO } from 'api/generated/dotu/dotu.schemas'
import { getGetApiProcessCalendarFastQueryKey } from 'api/generated/dotu/process'
import { useGetApiStartCalendar } from 'api/generated/dotu/start'
import { NOTIFICATIONS_REVALIDATE_TIME } from 'constants/hydrate'
import { isAfter } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'
import { useEffect, useState } from 'react'
import { getCastStatus } from './calendarStatus'
import { useDateFns } from './date'
import { DEFAULT_TIMEZONE } from './timeZone'

export interface NotificationsState {
  resources: CalendarEventResource[]
  processes: ProcessEvent[]
}

const now = new Date()

export const useNotifications = () => {
  const [notifications, setNotifications] = useState<NotificationsState>({
    resources: [],
    processes: []
  })

  const dateFns = useDateFns()

  const [isLoading, setLoading] = useState(true)

  const { data: calendarData, refetch } = useCalendarListQuery(
    dateFns.startOfDay(now),
    undefined,
    undefined,
    [2],
    NOTIFICATIONS_REVALIDATE_TIME
  )
  const { data: startData } = useGetApiStartCalendar()

  const queryClient = useQueryClient()

  useEffect(() => {
    if (startData) {
      const processes = [...calendarData].filter(data => {
        const start = utcToZonedTime(data.start, DEFAULT_TIMEZONE.name)

        return (
          data.eventType === 'process' &&
          getCastStatus(data.cast.status) === 'PENDING' &&
          isAfter(start, new Date())
        )
      }) as ProcessEvent[]

      const resourcesAlerts = startData.resources
        ? startData.resources
            .filter(
              resource => !resource.contractPrivacy || !resource.contractTerms
            )
            .map(resource => ({
              ...resource,
              company: startData?.companies?.find(
                company => company.id === resource.companyId
              )
            }))
        : []

      if (
        JSON.stringify(resourcesAlerts) !==
          JSON.stringify(notifications.resources) ||
        JSON.stringify(processes) !== JSON.stringify(notifications.processes)
      ) {
        setNotifications({ resources: resourcesAlerts, processes })
        setLoading(false)

        if (
          JSON.stringify(processes) !== JSON.stringify(notifications.processes)
        ) {
          const dates: { start: Date; end: Date }[] = []

          processes.forEach(process => {
            const date = new Date(process.start)
            const start = dateFns.startOfMonth(date)
            const end = dateFns.endOfMonth(date)

            if (dates.find(date => date.start === start && date.end === end)) {
              return
            }

            const previousData = queryClient.getQueryData(
              getGetApiProcessCalendarFastQueryKey({
                from: start.toISOString(),
                to: end.toISOString()
              })
            ) as CalendarFastResponseDTO
            const cacheProcess = previousData?.processes?.find(
              p => p.id === process.id
            )

            if (
              cacheProcess?.cast?.some(
                c =>
                  c.id === process.cast.id &&
                  getCastStatus(c.status) !== 'PENDING'
              )
            ) {
              dates.push({ start, end })
            }
          })

          dates.forEach(date => {
            queryClient.invalidateQueries(
              getGetApiProcessCalendarFastQueryKey({
                from: date.start.toISOString(),
                to: date.end.toISOString()
              })
            )
          })
        }
      } else {
        setLoading(false)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    calendarData,
    notifications.processes,
    notifications.resources,
    startData
  ])

  return {
    ...notifications,
    refetch,
    isLoading
  }
}
