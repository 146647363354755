import {
  faArrowRightFromBracket,
  faGear,
  faLanguage
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Divider from '@mui/material/Divider'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import MenuItem from '@mui/material/MenuItem'
import { LINKS } from 'constants/links'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { useLogout } from 'utils/auth/useLogout'

interface LayoutAppUserMenuPopupProps {
  onClose: () => void
  onLanguageView: () => void
}

export function LayoutAppUserMenuPopup({
  onClose,
  onLanguageView
}: LayoutAppUserMenuPopupProps) {
  const { t } = useTranslation(['app'])
  const logout = useLogout()
  const router = useRouter()

  return (
    <>
      <MenuItem onClick={onLanguageView}>
        <ListItemIcon>
          <FontAwesomeIcon icon={faLanguage} />
        </ListItemIcon>
        <ListItemText>{t('app:userMenu.language')}</ListItemText>
      </MenuItem>

      <MenuItem
        onClick={() => {
          if (router.pathname !== LINKS.appSettingsGeneral) {
            router.push(LINKS.appSettingsGeneral)
          }
          onClose()
        }}
      >
        <ListItemIcon>
          <FontAwesomeIcon icon={faGear} />
        </ListItemIcon>
        <ListItemText>{t('app:userMenu.settings')}</ListItemText>
      </MenuItem>
      <Divider />

      <MenuItem onClick={logout}>
        <ListItemIcon>
          <FontAwesomeIcon icon={faArrowRightFromBracket} />
        </ListItemIcon>
        <ListItemText>{t('app:userMenu.logout')}</ListItemText>
      </MenuItem>
    </>
  )
}
