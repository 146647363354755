import type { Dispatch, PropsWithChildren, SetStateAction } from 'react'
import { createContext, useContext, useEffect, useMemo, useState } from 'react'

import { useGetApiUser } from 'api/generated/dotu/user'

import { LinkModal } from './LinkModal'

import { localStorageClient } from 'utils/dom'

interface LinkModalContextValues {
  isChoiceModalOpen: boolean
  isGoogleModalOpen: boolean
  isIcalModalOpen: boolean
  isNotifyEnabled: boolean
  setGoogleModalOpen: Dispatch<SetStateAction<boolean>>
  setIcalModalOpen: Dispatch<SetStateAction<boolean>>
  setChoiceModalOpen: Dispatch<SetStateAction<boolean>>
  setNotifyEnabled: Dispatch<SetStateAction<boolean>>
  openGoogleModal: (enableNotify: boolean) => void
  openIcalModal: (enableNotify: boolean) => void
  openChoiceModal: () => void
}

const LinkModalContext = createContext<LinkModalContextValues>({
  isChoiceModalOpen: false,
  isGoogleModalOpen: false,
  isIcalModalOpen: false,
  isNotifyEnabled: false,
  setGoogleModalOpen: () => {
    throw new Error('Function not implemented.')
  },
  setIcalModalOpen: () => {
    throw new Error('Function not implemented.')
  },
  setChoiceModalOpen: () => {
    throw new Error('Function not implemented.')
  },
  setNotifyEnabled: () => {
    throw new Error('Function not implemented.')
  },
  openGoogleModal: () => {
    throw new Error('Function not implemented.')
  },
  openChoiceModal: () => {
    throw new Error('Function not implemented.')
  },
  openIcalModal: () => {
    throw new Error('Function not implemented.')
  }
})

export const useLinkModal = () => {
  const context = useContext(LinkModalContext)

  if (!context) {
    throw new Error('useLinkModal must be used within a LinkModalContext')
  }

  return context
}

export function ICallModalContextProvider({ children }: PropsWithChildren) {
  const [isGoogleModalOpen, setGoogleModalOpen] = useState<boolean>(false)
  const [isIcalModalOpen, setIcalModalOpen] = useState<boolean>(false)
  const [isChoiceModalOpen, setChoiceModalOpen] = useState<boolean>(false)
  const [isNotifyEnabled, setNotifyEnabled] = useState<boolean>(false)
  const { data } = useGetApiUser()

  useEffect(() => {
    const storageValue = localStorageClient.getItem('showLinkModal')

    if (data && (!storageValue || storageValue === 'true')) {
      const parsed = data.additionalData
        ? JSON.parse(data.additionalData)
        : undefined

      if (!parsed?.displayIntroModal || parsed.displayIntroModal === true) {
        setChoiceModalOpen(true)
      }
    }
  }, [data])

  const openGoogleModal = (enableNotify: boolean) => {
    setGoogleModalOpen(true)
    setNotifyEnabled(enableNotify)
  }

  const openIcalModal = (enableNotify: boolean) => {
    setIcalModalOpen(true)
    setNotifyEnabled(enableNotify)
  }

  const openChoiceModal = () => {
    setChoiceModalOpen(true)
    setGoogleModalOpen(false)
    setIcalModalOpen(false)
    setNotifyEnabled(true)
  }

  const contextState: LinkModalContextValues = useMemo(
    () => ({
      isChoiceModalOpen,
      isGoogleModalOpen,
      isIcalModalOpen,
      isNotifyEnabled,
      setChoiceModalOpen,
      setGoogleModalOpen,
      setIcalModalOpen,
      setNotifyEnabled,
      openGoogleModal,
      openIcalModal,
      openChoiceModal
    }),
    [isChoiceModalOpen, isGoogleModalOpen, isIcalModalOpen, isNotifyEnabled]
  )

  return (
    <LinkModalContext.Provider value={contextState}>
      <LinkModal />

      {children}
    </LinkModalContext.Provider>
  )
}
