import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import Stack from '@mui/material/Stack'

import { useTranslation } from 'next-i18next'

interface LinkModalActionsProps {
  variant: 'google' | 'ical' | 'choice'
  onClose: () => void
  onNotify: () => void
  displayNotify?: boolean
}

export function LinkModalActions({
  variant,
  onClose,
  onNotify,
  displayNotify
}: LinkModalActionsProps) {
  const { t } = useTranslation(['app'])

  const getPrimaryActionText = () => {
    if (variant === 'google') {
      return t('app:linkModal.actions.linkGoogle')
    }

    return t('app:linkModal.actions.close')
  }

  return (
    <Box>
      {displayNotify && <Divider />}

      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        gap={2}
        sx={{ px: 4, py: 2.5 }}
      >
        <Button size="small" variant="contained" onClick={onClose} fullWidth>
          {getPrimaryActionText()}
        </Button>

        {displayNotify && (
          <Button size="small" variant="outlined" onClick={onNotify} fullWidth>
            {t('app:linkModal.actions.notify')}
          </Button>
        )}
      </Stack>
    </Box>
  )
}
