import DateFnsAdapter from '@date-io/date-fns'
import cs from 'date-fns/locale/cs'
import enUS from 'date-fns/locale/en-US'
import { useRouter } from 'next/router'

export const useDateFns = () => {
  const router = useRouter()

  let locale
  if (router.locale === 'en') {
    locale = enUS
  } else {
    locale = cs
  }

  return new DateFnsAdapter({ locale })
}

export const useTimeRangeLabel = () => {
  const dateFns = useDateFns()

  return (start: Date, end: Date) => {
    if (dateFns.isSameDay(start, end)) {
      const timePeriod = `${dateFns.format(
        start,
        'fullTime'
      )} – ${dateFns.format(end, 'fullTime')}`
      return timePeriod
    } else {
      const startDate = dateFns.format(start, 'fullDateTime')
      const endDate = dateFns.format(end, 'fullDateTime')
      return `${startDate} – ${endDate}`
    }
  }
}
