import { DehydratedState, QueryClient, dehydrate } from '@tanstack/react-query'

export async function refetchQueries(
  queries: { refetch: () => Promise<unknown> }[]
) {
  return Promise.all(queries.map(query => query.refetch()))
}

export type DehydratedPrefetchResult = [DehydratedState | null, number | null]
export type PrefetchResult = [QueryClient | null, number | null]

export function dehydrateResponse(
  prefetchResult: PrefetchResult
): DehydratedPrefetchResult {
  const [queryClient, errorCode] = prefetchResult
  return [queryClient ? dehydrate(queryClient) : null, errorCode]
}
