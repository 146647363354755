import MuiAvatar from '@mui/material/Avatar'
import Tooltip from '@mui/material/Tooltip'
import Image from 'next/image'
import { stringAvatar } from './Avatar.utils'

interface AvatarProps {
  name?: string | null
  url?: string | null
  tooltip?: string
  center?: boolean
  primary?: boolean
  disableTooltip?: boolean
  size?: number
}

export function Avatar({
  name,
  url,
  tooltip,
  disableTooltip,
  center,
  primary,
  size = 40
}: AvatarProps) {
  const getContent = () => {
    if (url) {
      return (
        <MuiAvatar
          sx={{
            width: size,
            height: size,
            ...(center && {
              margin: '0 auto'
            })
          }}
        >
          <Image alt={name ?? ''} src={url} width={size} height={size} />
        </MuiAvatar>
      )
    } else if (name) {
      return <MuiAvatar {...stringAvatar(name, center, primary, size)} />
    } else {
      return (
        <MuiAvatar
          sx={{
            bgcolor: 'primary.main',
            width: size,
            height: size,
            ...(center && {
              margin: '0 auto'
            })
          }}
        ></MuiAvatar>
      )
    }
  }

  return (
    <Tooltip title={!disableTooltip ? tooltip || name : undefined}>
      {getContent()}
    </Tooltip>
  )
}
