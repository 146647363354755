import { useQueryClient } from '@tanstack/react-query'
import { signOut } from 'next-auth/react'

export const useLogout = () => {
  const queryClient = useQueryClient()

  const logout = async () => {
    await signOut()
    localStorage.clear()
    sessionStorage.clear()

    queryClient.getQueryCache().clear()
  }

  return logout
}
