import type { ReactElement } from 'react'

import { usePutApiUser } from 'api/generated/dotu/user'
import { useLinkModal } from './LinkModalContext'

import Box from '@mui/material/Box'

import { localStorageClient } from 'utils/dom'
import { ModalBase } from '../ModalBase'
import { LinkModalActions } from './LinkModalActions'
import { LinkModalChoice } from './LinkModalChoice'
import { LinkModalGoogle } from './LinkModalGoogle'
import { LinkModalIcal } from './LinkModalIcal'

export function LinkModal(): ReactElement {
  const {
    isChoiceModalOpen,
    isNotifyEnabled,
    isGoogleModalOpen,
    isIcalModalOpen,
    openGoogleModal,
    openIcalModal,
    setChoiceModalOpen,
    setIcalModalOpen,
    setGoogleModalOpen
  } = useLinkModal()
  const { mutate } = usePutApiUser()

  const renderContent = () => {
    if (isGoogleModalOpen) {
      return <LinkModalGoogle />
    }

    if (isIcalModalOpen) {
      return <LinkModalIcal />
    }

    if (isChoiceModalOpen) {
      return (
        <LinkModalChoice
          onGoogleModal={() => openGoogleModal(true)}
          onIcalModal={() => openIcalModal(true)}
        />
      )
    }
    return undefined
  }

  const getDisplayedVariant = () => {
    if (isGoogleModalOpen) {
      return 'google'
    }
    if (isIcalModalOpen) {
      return 'ical'
    }
    return 'choice'
  }

  const onBack = () => {
    setIcalModalOpen(false)
    setGoogleModalOpen(false)
  }

  const closeModal = () => {
    setChoiceModalOpen(false)
    setGoogleModalOpen(false)
    setIcalModalOpen(false)
  }

  const onClose = () => {
    if (isChoiceModalOpen) {
      localStorageClient.setItem('showLinkModal', 'false')
      mutate({
        data: {
          additionalData: JSON.stringify({
            displayIntroModal: false
          })
        }
      })
    }
    closeModal()
  }

  const onNotify = () => {
    localStorageClient.setItem('showLinkModal', 'false')
    mutate({
      data: {
        additionalData: JSON.stringify({
          displayIntroModal: true
        })
      }
    })
    closeModal()
  }

  const displayNotify = isChoiceModalOpen || isNotifyEnabled

  return (
    <ModalBase
      sx={{ p: 0, pb: 0 }}
      isOpened={isChoiceModalOpen || isGoogleModalOpen || isIcalModalOpen}
      onBack={
        isChoiceModalOpen && (isGoogleModalOpen || isIcalModalOpen)
          ? onBack
          : undefined
      }
      onClose={onClose}
    >
      <Box sx={{ px: 4, pb: displayNotify ? 5 : 2.5 }}>{renderContent()}</Box>

      <LinkModalActions
        onClose={onClose}
        onNotify={onNotify}
        variant={getDisplayedVariant()}
        displayNotify={displayNotify}
      />
    </ModalBase>
  )
}
