import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Badge from '@mui/material/Badge'
import Box from '@mui/material/Box'
import Link from '@mui/material/Link'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { LINKS } from 'constants/links'
import { useRouter } from 'next/router'
import { useNotifications } from 'utils/notifications'
import { useMenuItems } from './LayoutAppMenu.utils'

export function LayoutAppMenu() {
  const menuItems = useMenuItems()
  const router = useRouter()

  const { typography, breakpoints } = useTheme()
  const isSmallDevice = useMediaQuery(breakpoints.down('sm'))

  const notifications = useNotifications()

  return (
    <Stack direction="row" gap={{ xs: 5, sm: 8 }}>
      {menuItems.map(item =>
        router.pathname === item.baseUrl ? (
          <Box key={item.label} color="primary.main">
            <Stack direction="row" alignItems="center" gap={1.5}>
              <Badge
                badgeContent={
                  item.link === LINKS.appAccounts
                    ? notifications.resources.length
                    : notifications.processes.length
                }
                color="error"
              >
                <FontAwesomeIcon
                  icon={item.icon}
                  fontSize={isSmallDevice ? '1.25rem' : '1.5rem'}
                  opacity={typography.opacity.medium}
                />
              </Badge>

              {!isSmallDevice && (
                <Typography fontWeight="bold" color="primary.main">
                  {item.label}
                </Typography>
              )}
            </Stack>
          </Box>
        ) : (
          <Link
            key={item.label}
            href={item.link}
            underline="none"
            color="text.secondary"
          >
            <Stack direction="row" alignItems="center" gap={1.5}>
              <Badge
                badgeContent={
                  item.link === LINKS.appAccounts
                    ? notifications.resources.length
                    : notifications.processes.length
                }
                color="error"
              >
                <FontAwesomeIcon
                  icon={item.icon}
                  fontSize={isSmallDevice ? '1.25rem' : '1.5rem'}
                  opacity={typography.opacity.medium}
                />
              </Badge>
              {!isSmallDevice && (
                <Typography fontWeight="bold">{item.label}</Typography>
              )}
            </Stack>
          </Link>
        )
      )}
    </Stack>
  )
}
