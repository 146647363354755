import {
  CalendarCastDTO,
  CalendarFastResponseDTO,
  CalendarProcessDTO,
  CalendarStartDTO,
  PartDTO,
  PlaceDTO,
  ProcedureDTO,
  SessionCompanyDTO,
  SessionResourceDTO
} from 'api/generated/dotu/dotu.schemas'
import { getCastStatus, getProcessStatus } from 'utils/calendarStatus'

export interface CalendarEventResource extends SessionResourceDTO {
  processId?: number
  company?: SessionCompanyDTO
}

interface MappedEvent extends Omit<CalendarProcessDTO, 'place'> {
  eventType: 'process' | 'personalEvent' | 'tourProcess' | 'tour'
  resource?: SessionResourceDTO
  company?: SessionCompanyDTO
  place?: PlaceDTO
  title?: string
  start: Date
  end: Date
}

export interface TourEvent extends MappedEvent {
  eventType: 'tour'
  dateType: 'start' | 'end'
  range: {
    start: Date
    end: Date
  }
}

interface PersonalEventSingle extends MappedEvent {
  eventType: 'personalEvent'
  resource: CalendarEventResource
  note?: string
}

export interface PersonalEvent extends Omit<PersonalEventSingle, 'resourceId'> {
  resources: CalendarEventResource[]
}

export interface MappedCast extends CalendarCastDTO {
  part?: PartDTO
}

export interface ProcessEventSingle
  extends Omit<MappedEvent, 'cast' | 'procedure'> {
  eventType: 'process' | 'tourProcess'
  resources: CalendarEventResource[]
  procedure?: ProcedureDTO
  cast: MappedCast
}

export interface ProcessEvent extends ProcessEventSingle {
  tourProcesses: ProcessEventSingle[] | null
  tour: CalendarProcessDTO | null
}

export type CalendarEvent = PersonalEvent | ProcessEvent | TourEvent

const resolvePersonalEvent = (
  personalEvent: CalendarProcessDTO,
  entitiesData: CalendarStartDTO
): PersonalEventSingle | null => {
  if (!personalEvent.startDate || !personalEvent.endDate) {
    return null
  }

  const resource = entitiesData.resources?.find(
    resource => resource.id === personalEvent.resourceId
  )

  return {
    ...personalEvent,
    note: personalEvent.note ?? undefined,
    eventType: 'personalEvent',
    start: new Date(personalEvent.startDate),
    end: new Date(personalEvent.endDate),
    place: undefined,
    title: personalEvent.name ?? undefined,
    resource: {
      ...resource,
      processId: personalEvent.id,
      company: entitiesData.companies?.find(
        company => company.id === resource?.companyId
      )
    },
    company: entitiesData.companies?.find(
      company => company.id === personalEvent.companyId
    )
  }
}

const resolveProcessData = (
  process: CalendarProcessDTO,
  cast: CalendarCastDTO,
  entitiesData: CalendarStartDTO
): ProcessEventSingle | null => {
  const castStatus = cast.status ? getCastStatus(cast.status) : 'HIDDEN'
  const processStatus = process.status
    ? getProcessStatus(process.status)
    : 'HIDDEN'
  const resource = entitiesData.resources?.find(
    resource => resource.id === cast.resourceId
  )
  const procedure = entitiesData.procedures?.find(
    procedure => procedure.id === process.procedureId
  )

  if (
    castStatus !== 'HIDDEN' &&
    processStatus !== 'HIDDEN' &&
    resource?.contractPrivacy &&
    resource.contractTerms &&
    process.startDate &&
    process.endDate
  ) {
    return {
      ...process,
      eventType: process.tourId ? 'tourProcess' : 'process',
      start: new Date(process.startDate),
      end: new Date(process.endDate),
      company: entitiesData.companies?.find(
        company => company.id === process.companyId
      ),
      procedure: procedure,
      place: entitiesData.places?.find(place => place.id === process.placeId),
      resources: [
        {
          ...resource,
          company: entitiesData.companies?.find(
            company => company.id === resource.companyId
          )
        }
      ],
      title: process.name ?? undefined,
      cast: {
        ...cast,
        part: procedure?.parts?.find(part => part.id === cast.partId)
      }
    }
  } else {
    return null
  }
}

const groupPersonalEvents = (
  personalEvents: PersonalEventSingle[]
): PersonalEvent[] => {
  const grouped: PersonalEvent[] = []

  personalEvents.forEach(personalEvent => {
    const existing = personalEvent.referenceId
      ? grouped.find(
          event =>
            event.referenceId === personalEvent.referenceId &&
            event.start.getTime() === personalEvent.start.getTime()
        )
      : null

    if (existing) {
      const index = grouped.findIndex(
        event =>
          event.referenceId === personalEvent.referenceId &&
          event.start.getTime() === personalEvent.start.getTime()
      )
      if (personalEvent.resource) {
        grouped[index].resources.push(personalEvent.resource)
      }
    } else if (personalEvent.resource) {
      grouped.push({
        ...personalEvent,
        resources: [personalEvent.resource]
      })
    }
  })

  return grouped
}

const groupProcesses = (
  processEvents: ProcessEventSingle[],
  tours?: CalendarProcessDTO[]
): ProcessEvent[] => {
  const grouped: ProcessEvent[] = []

  processEvents.forEach(processEvent => {
    if (!processEvent.tourId) {
      grouped.push({
        ...processEvent,
        tour: null,
        tourProcesses: null
      })
      return
    }

    const existing = processEvent.id
      ? grouped.find(
          event =>
            (event.tourId &&
              event.tourId === processEvent.tourId &&
              event.resourceId === processEvent.resourceId) ||
            (!event.tourId &&
              event.resourceId === processEvent.resourceId &&
              event.cast.id === processEvent.cast.id)
        )
      : null

    if (existing) {
      const index = grouped.findIndex(
        event => event.tourId === processEvent.tourId
      )

      if (processEvent.start.getTime() < grouped[index].start.getTime()) {
        grouped[index].start = processEvent.start
        grouped[index].startDate = processEvent.startDate
      }
      if (processEvent.end.getTime() > grouped[index].end.getTime()) {
        grouped[index].end = processEvent.end
        grouped[index].endDate = processEvent.endDate
      }

      grouped[index].tourProcesses?.push(processEvent)
    } else if (processEvent?.tourId) {
      grouped.push({
        ...processEvent,
        tour: tours
          ? tours.find(tour => tour.id === processEvent.tourId) ?? null
          : null,
        tourProcesses: [processEvent]
      })
    }
  })

  return grouped
}

export const resolveCalendarData = (
  calendarListData?: CalendarFastResponseDTO,
  entitiesData?: CalendarStartDTO
): CalendarEvent[] => {
  let mappedData: CalendarEvent[] = []

  if (calendarListData && entitiesData) {
    const mappedProcesses: ProcessEventSingle[] = []
    calendarListData.processes?.forEach(process => {
      process.cast?.forEach(cast => {
        const data = resolveProcessData(process, cast, entitiesData)
        if (
          data &&
          mappedProcesses.findIndex(
            mappedProcess =>
              mappedProcess.id === data.id &&
              mappedProcess.cast.id === data.cast.id
          ) === -1
        ) {
          mappedProcesses.push(data)
        }
      })
    })

    const mappedPersonalEvents: PersonalEventSingle[] = []
    calendarListData.personalEvents?.forEach(personalEvent => {
      const data = resolvePersonalEvent(personalEvent, entitiesData)
      if (data) {
        mappedPersonalEvents.push(data)
      }
    })

    const groupedProcesses = groupProcesses(
      mappedProcesses,
      calendarListData.tours ?? undefined
    )
    const groupedPersonalEvents = groupPersonalEvents(mappedPersonalEvents)

    const tours = calendarListData.tours
      ?.map(tour => {
        if (!tour.startDate || !tour.endDate) {
          return undefined
        }

        return {
          ...tour,
          start: new Date(tour.startDate),
          end: new Date(tour.endDate),
          eventType: 'tour'
        }
      })
      .filter(tour => tour !== undefined) as TourEvent[]

    const mappedTours: TourEvent[] = []

    tours.forEach(tour => {
      if (!tour) {
        return
      }

      mappedTours.push({
        ...tour,
        start: tour.start,
        end: tour.start,
        dateType: 'start',
        range: {
          start: tour.start,
          end: tour.end
        }
      })
      mappedTours.push({
        ...tour,
        start: tour.end,
        end: tour.end,
        dateType: 'end',
        range: {
          start: tour.start,
          end: tour.end
        }
      })
    })

    mappedData = [...groupedProcesses, ...groupedPersonalEvents, ...mappedTours]

    // sort all events by start date
    mappedData.sort((a, b) => a.start.getTime() - b.start.getTime())

    mappedData.sort((a, b) => {
      if (
        a.eventType === 'tour' &&
        a.dateType === 'start' &&
        b.eventType === 'tourProcess' &&
        a.start.getTime() === b.start.getTime()
      ) {
        return -1
      }
      return 0
    })
  }

  return mappedData
}
